import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import GlobalContext from "../../context";
import './patientList.css';
import {useTranslation} from "react-i18next";
import i18next from '../../../i18n';
import {newPatientButton} from "./patientlistStyles";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import axios, {AxiosResponse} from "axios";
import ConsentDialog from "./ConsentDialog";
import moment from "moment";

export default function PatientList() {

    const {currentCenterId} = useContext(GlobalContext);
    const [patientlist, setPatientlist] = useState<AxiosResponse>();
    const {t} = useTranslation();
    const {setSelectedPatientId} = useContext(GlobalContext);
    const {setSelectedPatientIdentification} = useContext(GlobalContext);
    const {currentPatient, setCurrentPatient} = useContext(GlobalContext);
    const {setAxiosPatient} = useContext(GlobalContext);
    const navigate = useNavigate();
    const {showConsentDialog, setShowConsentDialog} = useContext(GlobalContext);

    const columns: GridColDef[] = [
        {
            field: 'identification',
            headerName: i18next.t('patientlist.table.columns.headerNames.patientId'),
            type: 'string',
            flex: 1,
            minWidth: 100
        },
        {
            field: 'yearOfBirth',
            headerName: i18next.t('patientlist.table.columns.headerNames.birthYear'),
            type: 'string',
            flex: 2,
            minWidth: 100
        },
        {
            field: 'covidInfection',
            headerName: i18next.t('patientlist.table.columns.headerNames.covidInfection'),
            type: "date",
            flex: 2,
            minWidth: 100,
            valueFormatter: params =>
            {
                if (params?.value !== undefined && params?.value !== null){
                    return moment(params?.value)?.format("DD.MM.YYYY")
                }
            }
        },
        {
            field: 'entryDate',
            headerName: i18next.t('patientlist.table.columns.headerNames.entryDate'),
            type: "date",
            flex: 2,
            minWidth: 100,
            valueFormatter: params =>
            {
                if (params?.value !== undefined && params?.value !== null){
                    return moment(params?.value)?.format("DD.MM.YYYY")
                }
            }
        },
    ];

    const handleOnCellClick = (params: any) => {
        if (params.id) {
            // Patienten finden anhand der aktuell ausgewählten Tabellenzeile (trägt eine unique ID: params.id (= erste Tabellenspalte "Patienten ID))
            let selectedPatient = patientlist?.data.find((o: { id: any; }) => o.id === params.id);
            setSelectedPatientId(params.id);
            setCurrentPatient(currentPatient);

            // Axios Request, um das RICHTIGE Patientenobjekt zu bekommen, nicht nur das Skeleton
            //axios.get('http://localhost:8080/v1/patient/' + currentCenterId + '/' + selectedPatient.identification,
            axios.get('/v1/patient/' + currentCenterId + '/' + selectedPatient.identification,
                {withCredentials: true})
                .then(result => {
                    setAxiosPatient(result.data);
                    setSelectedPatientIdentification(selectedPatient.identification);
                    //console.log("patient from backend: ", result.data)
                })
            navigate('/basicdata');
        }
    };

    const createNewPatient = () => {
        setShowConsentDialog(true);
    };

    useEffect(() => {
        axios.get('/v1/patient/center/' + currentCenterId,
        //axios.get('/v1/patient/center/' + currentCenterId,
            {withCredentials: true})
            .then(result => {
                setPatientlist(result);
            })
    }, [])

    let rows = patientlist?.data.map((element: { id: any, identification: any, yearOfBirth: any, covidInfection: any, entryDate: any }) => {
            return {
                id: element.id,
                identification: element.identification,
                yearOfBirth: element.yearOfBirth,
                covidInfection: element.covidInfection,
                entryDate: element.entryDate
            }
        }
    );

    return (
        <div className="patientlist-wrapper">
            <div className="patientlist-header-text">
                <div className="center-id">{t('patientlist.centerid')} {currentCenterId}</div>
                <header className="patientlist-header">{t('patientlist.title')}</header>
                <div className="patientlist-grid">
                    {patientlist &&
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        onCellClick={handleOnCellClick}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'identification', sort: 'asc' }],
                            },
                        }}
                    />
                    }
                    <div>
                        <Button
                            variant="contained"
                            sx={newPatientButton}
                            onClick={createNewPatient}
                            startIcon={<AddIcon/>}
                        >
                            {t('patientlist.addNewPatient')}
                        </Button>
                    </div>
                    {showConsentDialog &&
                        <ConsentDialog/>
                    }
                </div>
            </div>
        </div>
    );
}
