import React, {useContext, useEffect, useState} from "react";
import DropdownSelection from "../../../molecules/DropdownSelection/DropdownSelection";
import {useTranslation} from "react-i18next";
import GlobalContext from "../../../context";

export default function Vaccination() {
    const {t} = useTranslation();
    const {axiosPatient} = useContext(GlobalContext);
    const [placeholder, setPlaceholder] = useState("");

    const optionsVaccination: string[] = [
        t("mui.dropdownSelectionLabel.optionsVaccination.yes"),
        t("mui.dropdownSelectionLabel.optionsVaccination.no"),
    ];

    useEffect(()=>{
        if (axiosPatient.vaccinated === true) {
            setPlaceholder(t("mui.dropdownSelectionLabel.optionsVaccination.yes"));
        } else if (axiosPatient.vaccinated === false) {
            setPlaceholder(t("mui.dropdownSelectionLabel.optionsVaccination.no"));
        } else {
            setPlaceholder("");
        }
    },[t])

    return (
        <DropdownSelection
            options={optionsVaccination}
            placeholder={placeholder}
            label={t("mui.dropdownSelectionLabel.vaccination")}
            id={"vaccination"}
        />
    );
}

