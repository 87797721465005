import React from "react";
import "./headerHyperlink.css";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export default function HeaderHyperlinks() {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="header-hyperlink-container">
            <Button variant="text" sx={{color: "#2A57A5"}}
                    onClick={() => navigate('/contact')}>{t('pages.contact')}</Button>
            <Button variant="text" sx={{color: "#2A57A5"}}
                    onClick={() => navigate('/legalnotice')}>{t('pages.legalNotice')}</Button>
            <Button variant="text" sx={{color: "#2A57A5"}}
                    onClick={() => navigate('/privacypolicy')}>{t('pages.privacyPolicy')}</Button>
        </div>
    );
}

