interface LabValue {
    id: number;
    name: string;
    measurementDate: Date | undefined;
    patientId: number;
    pointOfMeasurement: string;
    unit: string;
    value: number;
}

const checkIfLabValueAlreadyRegisteredAndReplace = (
    entryToCheck: string,
    pointOfMeasurementToCheck: string,
    array: [{id: number, name: string, patientId: number, pointOfMeasurement: string, unit: string, value: number,
        measurementDate: Date}],
    axiosPatientInstance: any,
    eventFromInputMask: any,
    unit: string,
    measurementDate: Date | undefined) => {

    // create labvalue object to insert in case it is not already registered
    let labvalue: LabValue = {
        id: 0,
        name: entryToCheck,
        measurementDate: measurementDate,
        patientId: axiosPatientInstance.id,
        pointOfMeasurement: pointOfMeasurementToCheck,
        unit: unit,
        value: eventFromInputMask
    };

    const alreadyRegistered = array.find((object) => {
        return object.name === entryToCheck && object.pointOfMeasurement === pointOfMeasurementToCheck;
    });

    if (alreadyRegistered) {
        const index = array.findIndex(object => {
            return object.name === entryToCheck && object.pointOfMeasurement === pointOfMeasurementToCheck;
        });
        if (!labvalue.measurementDate) {
            labvalue.measurementDate = array[index].measurementDate;
        }
        labvalue.id = array[index].id;

        // @ts-ignore
        array[index] = labvalue;
    } else {
        // @ts-ignore
        array.push(labvalue);
    }
}

function saveLabValues(id: string, event: any, axiosPatient: any, currentPatient: any) {

    if (id.includes("Vor der Behandlung")) {
        const labValueToCheck = id.replace("Vor der Behandlung ", '');

        switch (labValueToCheck) {
            case "fibrinogen":
                checkIfLabValueAlreadyRegisteredAndReplace(
                    "fibrinogen",
                    "beforeTreatment",
                    axiosPatient.labValues,
                    axiosPatient,
                    event,
                    "g/l",
                    currentPatient.labValueDateBefore)
                break;
            case "igg":
                checkIfLabValueAlreadyRegisteredAndReplace(
                    "igg",
                    "beforeTreatment",
                    axiosPatient.labValues,
                    axiosPatient,
                    event,
                    "mg/dl",
                    currentPatient.labValueDateBefore)
                break;
            case "igm":
                checkIfLabValueAlreadyRegisteredAndReplace(
                    "igm",
                    "beforeTreatment",
                    axiosPatient.labValues,
                    axiosPatient,
                    event,
                    "mg/dl",
                    currentPatient.labValueDateBefore
                )
                break;
            case "iga":
                checkIfLabValueAlreadyRegisteredAndReplace(
                    "iga",
                    "beforeTreatment",
                    axiosPatient.labValues,
                    axiosPatient,
                    event,
                    "mg/dl",
                    currentPatient.labValueDateBefore)
                break;
            case "ige":
                checkIfLabValueAlreadyRegisteredAndReplace(
                    "ige",
                    "beforeTreatment",
                    axiosPatient.labValues,
                    axiosPatient,
                    event,
                    "mg/dl",
                    currentPatient.labValueDateBefore);
                break;
        }
    }

    else if (id.includes("Nach der letzten Sitzung")) {
        const labValueToCheck = id.replace("Nach der letzten Sitzung ", '');
        switch (labValueToCheck) {
            case "fibrinogen":
                checkIfLabValueAlreadyRegisteredAndReplace(
                    "fibrinogen",
                    "afterLastTreatment",
                    axiosPatient.labValues,
                    axiosPatient,
                    event,
                    "mg/dl",
                    currentPatient.labValueDateAfter)
                break;
            case "igg":
                checkIfLabValueAlreadyRegisteredAndReplace(
                    "igg",
                    "afterLastTreatment",
                    axiosPatient.labValues,
                    axiosPatient,
                    event,
                    "mg/dl",
                    currentPatient.labValueDateAfter)
                break;
            case "igm":
                checkIfLabValueAlreadyRegisteredAndReplace(
                    "igm",
                    "afterLastTreatment",
                    axiosPatient.labValues,
                    axiosPatient,
                    event,
                    "mg/dl",
                    currentPatient.labValueDateAfter)
                break;
            case "iga":
                checkIfLabValueAlreadyRegisteredAndReplace(
                    "iga",
                    "afterLastTreatment",
                    axiosPatient.labValues,
                    axiosPatient,
                    event,
                    "mg/dl",
                    currentPatient.labValueDateAfter)
                break;
            case "ige":
                checkIfLabValueAlreadyRegisteredAndReplace(
                    "ige",
                    "afterLastTreatment",
                    axiosPatient.labValues,
                    axiosPatient,
                    event,
                    "mg/dl",
                    currentPatient.labValueDateAfter)
                break;
        }
    }

    else if (id.includes("Nach 1 Monat")) {
        const labValueToCheck = id.replace("Nach 1 Monat ", '');

        switch (labValueToCheck) {
            case "fibrinogen":
                checkIfLabValueAlreadyRegisteredAndReplace(
                    "fibrinogen",
                    "afterOneMonth",
                    axiosPatient.labValues,
                    axiosPatient,
                    event,
                    "mg/dl",
                    currentPatient.labValueDateOneMonth)
                break;
            case "igg":
                checkIfLabValueAlreadyRegisteredAndReplace(
                    "igg",
                    "afterOneMonth",
                    axiosPatient.labValues,
                    axiosPatient,
                    event,
                    "mg/dl",
                    currentPatient.labValueDateOneMonth)
                break;
            case "igm":
                checkIfLabValueAlreadyRegisteredAndReplace(
                    "igm",
                    "afterOneMonth",
                    axiosPatient.labValues,
                    axiosPatient,
                    event,
                    "mg/dl",
                    currentPatient.labValueDateOneMonth)
                break;
            case "iga":
                checkIfLabValueAlreadyRegisteredAndReplace(
                    "iga",
                    "afterOneMonth",
                    axiosPatient.labValues,
                    axiosPatient,
                    event,
                    "mg/dl",
                    currentPatient.labValueDateOneMonth)
                break;
            case "ige":
                checkIfLabValueAlreadyRegisteredAndReplace(
                    "ige",
                    "afterOneMonth",
                    axiosPatient.labValues,
                    axiosPatient,
                    event,
                    "mg/dl",
                    currentPatient.labValueDateOneMonth)
                break;
        }
    }

    else if (id.includes("Nach 3 Monaten")) {
        const labValueToCheck = id.replace("Nach 3 Monaten ", '');

        switch (labValueToCheck) {
            case "fibrinogen":
                checkIfLabValueAlreadyRegisteredAndReplace(
                    "fibrinogen",
                    "afterThreeMonths",
                    axiosPatient.labValues,
                    axiosPatient,
                    event,
                    "mg/dl",
                    currentPatient.labValueDateThreeMonths)
                break;
            case "igg":
                checkIfLabValueAlreadyRegisteredAndReplace(
                    "igg",
                    "afterThreeMonths",
                    axiosPatient.labValues,
                    axiosPatient,
                    event,
                    "mg/dl",
                    currentPatient.labValueDateThreeMonths)
                break;
            case "igm":
                checkIfLabValueAlreadyRegisteredAndReplace(
                    "igm",
                    "afterThreeMonths",
                    axiosPatient.labValues,
                    axiosPatient,
                    event,
                    "mg/dl",
                    currentPatient.labValueDateThreeMonths)
                break;
            case "iga":
                checkIfLabValueAlreadyRegisteredAndReplace(
                    "iga",
                    "afterThreeMonths",
                    axiosPatient.labValues,
                    axiosPatient,
                    event,
                    "mg/dl",
                    currentPatient.labValueDateThreeMonths)
                break;
            case "ige":
                checkIfLabValueAlreadyRegisteredAndReplace(
                    "ige",
                    "afterThreeMonths",
                    axiosPatient.labValues,
                    axiosPatient,
                    event,
                    "mg/dl",
                    currentPatient.labValueDateThreeMonths)
                break;
        }
    }
}

export { saveLabValues };