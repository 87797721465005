import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import GlobalContext from "../../../context";
import './user.css';
import {Fab} from "@mui/material";
import {floatingButtonPatientList, floatingButtonSave} from "../../BasicData/basicDataStyles";
import CenterId from "./Fields/CenterId";
import Name from "./Fields/Name";
import UserId from "./Fields/UserId";
import Role from "./Fields/Role";

export default function User() {
    const {selectedUserId} = useContext(GlobalContext);
    const navigate = useNavigate();

    const goBackToUserList = () => {
        navigate('/admin');
    }

    return (
        <>
            <div className="user-header-text">
                <header className="inputMask-main-header">Übersicht Nutzer ID {selectedUserId}</header>
                <div className="user">
                    <Fab sx={floatingButtonPatientList} variant="extended" onClick={goBackToUserList}>
                        Zur Nutzerübersicht
                    </Fab>
                    <Fab sx={floatingButtonSave} variant="extended" onClick={goBackToUserList}>
                        Speichern
                    </Fab>

                    <UserId/>
                    <CenterId/>
                    <Role/>
                    <Name/>
                </div>

            </div>
        </>
    );
}

