import React, {useContext, useEffect, useState} from "react";
import DropdownSelection from "../../../../molecules/DropdownSelection/DropdownSelection";
import {useTranslation} from "react-i18next";
import GlobalContext, {defaultAxiosPatient} from "../../../../context";

type Props = {
    label: string,
    id: string
}

export default function AutoAntibody(props: Props) {
    const {t} = useTranslation();
    const {axiosPatient} = useContext(GlobalContext);
    const [placeholder, setPlaceholder] = useState("");

    const optionsAutoAntibodies: string[] = [
        t("singleInformation.aakOptions.negative"),
        t("singleInformation.aakOptions.borderline"),
        t("singleInformation.aakOptions.positive"),
        t("singleInformation.aakOptions.undefined"),
    ];

    //TODO: Einkürzen
    useEffect(() => {
        if (axiosPatient.antibodies !== null) {
            const antibody = axiosPatient.antibodies.find(antibody => antibody.name === props.id);
            if (antibody) {
                let translatedPlaceholder = '';
                if (antibody.state === 'negative') {
                    translatedPlaceholder = t("singleInformation.aakOptions.negative");
                } else if (antibody.state === 'positive') {
                    translatedPlaceholder = t("singleInformation.aakOptions.positive");
                } else if (antibody.state === 'borderline') {
                    translatedPlaceholder = t("singleInformation.aakOptions.borderline");
                } else if (antibody.state === 'undefined') {
                    translatedPlaceholder = t("singleInformation.aakOptions.undefined");
                }
                setPlaceholder(translatedPlaceholder);
            }
        } else {
            axiosPatient.antibodies = defaultAxiosPatient.antibodies;
            const antibody = axiosPatient.antibodies.find(antibody => antibody.name === props.id);
            if (antibody) {
                let translatedPlaceholder = '';
                if (antibody.state === 'negative') {
                    translatedPlaceholder = t("singleInformation.aakOptions.negative");
                } else if (antibody.state === 'positive') {
                    translatedPlaceholder = t("singleInformation.aakOptions.positive");
                } else if (antibody.state === 'borderline') {
                    translatedPlaceholder = t("singleInformation.aakOptions.borderline");
                } else if (antibody.state === 'undefined') {
                    translatedPlaceholder = t("singleInformation.aakOptions.undefined");
                }
                setPlaceholder(translatedPlaceholder);
            }
        }

    },[t])

    return (
        <DropdownSelection
            options={optionsAutoAntibodies}
            typeOfMeasurement={"autoantibody"}
            placeholder={placeholder}
            label={props.label}
            id={props.id}
        />
    );
}

