import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {ThemeProvider} from "@mui/material";
import {createTheme} from '@material-ui/core/styles'
import GlobalContext, {defaultAxiosPatient} from "../../context";
import {saveSymptomsValues} from "./SaveSymptomsValues";
import {setPlaceholderSymptoms} from "./SetPlaceholderSymptoms";
import {useTranslation} from "react-i18next";

export interface Props {
    label: string;
    id: string;
    change?: any;
    labValuesApprovedByPatient?: any;
    typeOfMeasurement?: string
}

export default function CheckboxLabeled(props: Props) {
    const {axiosPatient} = useContext(GlobalContext);
    const [isChecked, setIsChecked] = useState(false);
    const [value, setValue] = useState();
    const {t} = useTranslation();

    // Override typography of Material-UI's FormControlLabel
    const theme = createTheme({
        typography: {
            fontFamily: "'avenir', sans-serif",
        },
    })

    useEffect(()=> {
        if (props.typeOfMeasurement === "symptomsAfterCovid") {
            if (setPlaceholderSymptoms(props.id, props.label, axiosPatient, t)) {
                setIsChecked(setPlaceholderSymptoms(props.id, props.label, axiosPatient, t));
            }
        } else if (props.typeOfMeasurement === "approvalBioSampling") {
            setIsChecked(axiosPatient.approvalBioSampling)
        }
    }, [axiosPatient.symptoms])

    const handleChecked = (event: any) => {
        if (props.typeOfMeasurement === "symptomsAfterCovid") {
            if (axiosPatient.symptoms !== null) {
                saveSymptomsValues(props.id, event.target.name, axiosPatient, !isChecked, t);
            } else {
                // ToDo Problem wenn ein neuer Patient angelegt wird:
                // Das Backend schickt nicht direkt die PatientenId mit.
                // Deshalb geht das erste Speichern des Patienten krachen ("kenne keinen Patienten mit Id 0)
                // --> erst wenn man rausgeht, in der Tabelle auf den eben erzeugten Patienten klickt, ist die ID aus dem Backend da
                axiosPatient.symptoms = defaultAxiosPatient.symptoms;
                saveSymptomsValues(props.id, event.target.name, axiosPatient, !isChecked, t);
            }
        } else if (props.typeOfMeasurement === "approvalBioSampling") {
            axiosPatient.approvalBioSampling = !isChecked;
        }
    }

// Todo Dokumentieren: props.change(!isChecked)} anstelle von props.change(newLabValuesApprovedByPatient)}, weil isChecked
//  und newLabValuesApprovedByPatient 2 SingleSource of Truth sind,. Dadurch kommt die asynchrone Natur von useState
//  durcheinander: den StackOverflowBeitrag aus meiner History raussuchen
    // außerdem hilfreich: https://stackoverflow.com/questions/59272454/react-hooks-function-dependency/59274215#59274215

    return (
        <FormGroup>
            <ThemeProvider theme={theme}>
                <FormControlLabel
                    sx={{minWidth: '300px'}}
                    control={
                        <Checkbox
                            name={props.label}
                            checked={isChecked}
                            value={value}

                            onChange={event => {
                                setIsChecked(!isChecked);
                                handleChecked(event);
                                //props.change(!isChecked)
                            }
                            }
                        />
                    }
                    label={props.label}
                />
            </ThemeProvider>
        </FormGroup>
    );
}
