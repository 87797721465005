function setPlaceholderSymptoms(id: string, label: string, axiosPatient: any, t: any) {
    let value;

    // convert label of the symptoms to string that is stored in database
    let symptomsValuesName = '';

    switch (label) {
        case t('symptomsThatDidNotOccurBeforeCovid.weightLoss'):
            symptomsValuesName = 'weightLoss';
            break;
        case t('symptomsThatDidNotOccurBeforeCovid.muscleAche'):
            symptomsValuesName = 'muscularPain';
            break;
        case t('symptomsThatDidNotOccurBeforeCovid.generalPain'):
            symptomsValuesName = 'generalizedPain';
            break;
        case t('symptomsThatDidNotOccurBeforeCovid.flu'):
            symptomsValuesName = 'fluLikeSymptoms';
            break;
        case t('symptomsThatDidNotOccurBeforeCovid.fever'):
            symptomsValuesName = 'fever';
            break;
        case t('symptomsThatDidNotOccurBeforeCovid.fatigue'):
            symptomsValuesName = 'fatigue';
            break;
        case t('symptomsThatDidNotOccurBeforeCovid.jointPain'):
            symptomsValuesName = 'jointPain';
            break;
    }

    if (id.includes(t('timings.beforeTreatment'))) {
        for (let key in axiosPatient.symptoms) {
            if (symptomsValuesName in axiosPatient.symptoms[key] && axiosPatient.symptoms[key].pointOfMeasurement === "beforeTreatment") {
                let object = axiosPatient.symptoms[key];
                value = object[symptomsValuesName];
            }
        }
    } else if (id.includes(t('timings.afterLastTreatment'))) {
        for (let key in axiosPatient.symptoms) {
            if (symptomsValuesName in axiosPatient.symptoms[key] && axiosPatient.symptoms[key].pointOfMeasurement === "afterLastTreatment") {
                let object = axiosPatient.symptoms[key];
                value = object[symptomsValuesName];
            }
        }
    } else if (id.includes(t('timings.afterOneMonth'))) {
        for (let key in axiosPatient.symptoms) {
            if (symptomsValuesName in axiosPatient.symptoms[key] && axiosPatient.symptoms[key].pointOfMeasurement === "afterOneMonth") {
                let object = axiosPatient.symptoms[key];
                value = object[symptomsValuesName];
            }
        }
    } else if (id.includes(t('timings.afterThreeMonths'))) {
        for (let key in axiosPatient.symptoms) {
            if (symptomsValuesName in axiosPatient.symptoms[key] && axiosPatient.symptoms[key].pointOfMeasurement === "afterThreeMonths") {
                let object = axiosPatient.symptoms[key];
                value = object[symptomsValuesName];
            }
        }
    }
    return value;
}

export {setPlaceholderSymptoms};