import React from "react";
import MultiSelection from "../../../../molecules/MultiSelection/MultiSelection";

export default function Role() {

    const optionsUsers: string[] = [
        "Nutzer",
        "Monitor",
        "Admin",
    ];

    return (
        <MultiSelection
            options={optionsUsers}
            label={"Rolle"}
            id={"role"}
            labelFreeText={"Weitere Rolle hinzufügen"}
            idFreeText={"add new role"}/>
    );
}

