const datePickerDesktop = {
    width: "90%",
    paddingBottom: "3%"
}

const datePickerTablet = {
    width: "100%",
    paddingBottom: "3%"
}

export { datePickerDesktop, datePickerTablet };