import React from "react";
import { useTranslation } from 'react-i18next';
import CookieConsent from "react-cookie-consent";
import './landingpage.css';
import covid_mask from "../../../assets/covid_mask.jpg";
import logo from "../../../assets/isfa-eu.svg";
import covidvirus from "../../../assets/covid_virus.jpg";

export default function Landingpage() {

    const {t} = useTranslation();

    return (
        <div className="landingpage-wrapper">
            <CookieConsent buttonText="Verstanden!">
                Wir verwenden Cookies. Nähere Informationen dazu finden Sie in unserer Datenschutzerklärung.
            </CookieConsent>

            <div className="landingpage-header-text-image">
                <img src={covid_mask} alt={'by pixabay'}/>

                <div className="landingpage-header-text">
                    <div className="landingpage-header">{t('subtitle')}</div>
                    <div className="landingpage-text-wrapper">
                        <div>
                            {t('pages.landingpage.section1')}
                        </div>
                        <br></br>
                        <div>
                            {t('pages.landingpage.section2')}
                        </div>
                        <br></br>
                        <div>
                            {t('pages.landingpage.section3')}
                        </div>
                    </div>
                </div>
                    <img src={covidvirus} alt={'by pixabay'}/>
            </div>
            <img className="landingpage-logo" src={logo} alt={'replace with e-isfa logo'}/>
        </div>

    );
}

