function setPlaceholderScores(id: string, label: string, axiosPatient: any, t: any) {
    let value;

    // convert label of the labvalues to string that is stored in database
    let scoreValuesName = '';
    switch (label) {
        case '6 Minute Walk (in m)':
            scoreValuesName = 'sixMinuteWalk';
            break;
        case 'EQ5 Score':
            scoreValuesName = 'eq5Score';
            break;
        case 'Borg-CR-Skala (Wert)' || 'Borg-CR-Scale (Value)':
            scoreValuesName = 'borgCr';
            break;
        case 'Borg-RPE-Skala (Wert)' || 'Borg-RPE-Scale (Value)':
            scoreValuesName = 'borgRpe';
            break;
        case 'Montreal Cognitive Assessment Score MoCA':
            scoreValuesName = 'moca';
            break;
        case 'Epworth Sleepiness Scale':
            scoreValuesName = 'epworthSleepinessScale';
            break;
        case 'Beck Depression Inventar Version I  BDI':
            scoreValuesName = 'bdi';
            break;
        case 'Fatigue Severity Scale FSS':
            scoreValuesName = 'fss';
            break;
    }

    const setPlaceholderScoreByPointOfMeasurement = (pointOfMeasurement: string) => {
        for (let key in axiosPatient.scores) {
            if (scoreValuesName in axiosPatient.scores[key] && axiosPatient.scores[key].pointOfMeasurement === pointOfMeasurement) {
                let object = axiosPatient.scores[key];
                value = object[scoreValuesName];
            }
        }
    }

    if (id.includes(t('timings.beforeTreatment'))) {
        setPlaceholderScoreByPointOfMeasurement("beforeTreatment");
    } else if (id.includes(t('timings.afterLastTreatment'))) {
        setPlaceholderScoreByPointOfMeasurement("afterLastTreatment");
    } else if (id.includes(t('timings.afterOneMonth'))) {
        setPlaceholderScoreByPointOfMeasurement("afterOneMonth");
    } else if (id.includes(t('timings.afterThreeMonths'))) {
        setPlaceholderScoreByPointOfMeasurement("afterThreeMonths");
    }
    return value;
}

export {setPlaceholderScores};