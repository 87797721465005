import React from "react";
import MultiSelection from "../../../../molecules/MultiSelection/MultiSelection";

export default function CenterId() {

    const optionsCenters: string[] = [
        "Center 1",
        "Center 2",
        "Center 3",
    ];

    return (
        <>
            <MultiSelection
                options={optionsCenters}
                label={"Zugeordnete Zentren"}
                id={"userCenters"}
                labelFreeText={"Weiteres Zentrum hinzufügen"}
                idFreeText={"add new user"}/>
        </>
    );
}

