
function setPlaceholderLabValues(id: string, label: string, axiosPatient: any, t: any) {

    let value;
    // convert label of the labvalues to string that is stored in database
    let labValuesName = '';

    switch (label) {
        case 'Fibrinogen':
            labValuesName = 'fibrinogen';
            break;
        case 'IgG':
            labValuesName = 'igg';
            break;
        case 'IgM':
            labValuesName = 'igm';
            break;
        case 'IgA':
            labValuesName = 'iga';
            break;
        case 'IgE':
            labValuesName = 'ige';
            break;
    }

    const setPlaceholderLabValueByPointOfMeasurement = (pointOfMeasurement: string) => {
        for (let key in axiosPatient.labValues) {
            if (axiosPatient.labValues[key].pointOfMeasurement === pointOfMeasurement && axiosPatient.labValues[key].name === labValuesName) {
                value = axiosPatient.labValues[key].value;
            }
        }
    }

    if (id.includes(t('timings.beforeTreatment'))) {
        setPlaceholderLabValueByPointOfMeasurement('beforeTreatment');
    } else if (id.includes(t('timings.afterLastTreatment'))) {
        setPlaceholderLabValueByPointOfMeasurement('afterLastTreatment');
    } else if (id.includes(t('timings.afterOneMonth'))) {
        setPlaceholderLabValueByPointOfMeasurement('afterOneMonth');
    } else if (id.includes(t('timings.afterThreeMonths'))) {
        setPlaceholderLabValueByPointOfMeasurement('afterThreeMonths');
    }
    return value;
}

export {setPlaceholderLabValues};