import {blue} from "@mui/material/colors";

const dialog = {
    "& .MuiDialog-container": {
        "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",
        },
    },
}

const cancelButton = {
    marginRight: "5%",
    minWidth: "150px",
};

const proceedButton = {
    minWidth: "150px",
};

const icons = {
    bgcolor: blue[100],
    color: blue[600]
}

const listItemInput = {
    alignItems: "flex-end",
}

const listItemButtons = {
    justifyContent: "center",
    paddingTop: "5%"
}


export { dialog, cancelButton, proceedButton, icons, listItemInput, listItemButtons };