import React from "react";
import SymptomsAfterCovidSingle from "../SymptomsAfterCovidSingle/SymptomsAfterCovidSingle";
import '../clinicalEvaluation.css';
import '../../../../App.css';
import {useTranslation} from "react-i18next";

type Props = {
    label: string,
}

export default function SymptomsAfterCovidGroup(props: Props) {
    const {t} = useTranslation();

    return (
        <>
            <header className="treatmentTimesHeading">{props.label}</header>
            <div className="symptomsAfterCovid-group-container">
                <SymptomsAfterCovidSingle label={t('symptomsThatDidNotOccurBeforeCovid.weightLoss')} id={props.label + " weightloss"}/>
                <SymptomsAfterCovidSingle label={t('symptomsThatDidNotOccurBeforeCovid.muscleAche')} id={props.label + " muscleache"}/>
                <SymptomsAfterCovidSingle label={t('symptomsThatDidNotOccurBeforeCovid.generalPain')} id={props.label + " generalpain"}/>
                <SymptomsAfterCovidSingle label={t('symptomsThatDidNotOccurBeforeCovid.flu')} id={props.label + " flu"}/>
                <SymptomsAfterCovidSingle label={t('symptomsThatDidNotOccurBeforeCovid.fever')} id={props.label + " fever"}/>
                <SymptomsAfterCovidSingle label={t('symptomsThatDidNotOccurBeforeCovid.fatigue')} id={props.label + " fatigue"}/>
                <SymptomsAfterCovidSingle label={t('symptomsThatDidNotOccurBeforeCovid.jointPain')} id={props.label + " jointPain"}/>
            </div>
        </>
    );
}

