import React, {useContext} from "react";
import CalendarInput from "../../../atoms/CalendarInput/CalendarInput";
import './infectionDate.css';
import {useTranslation} from "react-i18next";
import GlobalContext from "../../../context";

export default function InfectionDate() {
    const {axiosPatient} = useContext(GlobalContext);
    const {t} = useTranslation();

    return (
        <div className="calendar-InfectionDate">
            <CalendarInput
                id={"infectionDate"}
                placeholder={axiosPatient.covidInfection}
                label={t("mui.calendarInputLabel.infectionDate")}
                inputFormat={"dd.MM.yyyy"}
            />
        </div>
    );
}

