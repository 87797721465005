import React, {useContext, useEffect, useState} from "react";
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import PasswordIcon from '@mui/icons-material/Password';
import Button from '@mui/material/Button';
import InputText from "../../atoms/InputText/InputText";
import GlobalContext from "../../context";
import {dialog, cancelButton, proceedButton, icons, listItemInput, listItemButtons} from "./loginStyles";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {DialogActions, DialogContent, DialogContentText} from "@mui/material";

export default function Login() {
    const {showLoginDialog, setShowLoginDialog} = useContext(GlobalContext);
    const {setButtonLoginLogout} = useContext(GlobalContext);
    const {username} = useContext(GlobalContext);
    const {password} = useContext(GlobalContext);
    const {oldPassword} = useContext(GlobalContext);
    const {newPassword1} = useContext(GlobalContext);
    const {newPassword2} = useContext(GlobalContext);
    const {setCurrentUser} = useContext(GlobalContext);
    const {setCurrentCenterId} = useContext(GlobalContext);
    const {setIsAuthenticated} = useContext(GlobalContext);
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [showNewPasswordDialog, setShowNewPasswordDialog] = useState(false);
    const {t} = useTranslation();

    useEffect(()=>{
        setShowError(false);
    }, [showNewPasswordDialog, showLoginDialog])

    const signIn = () => {
        // axios.post('http://localhost:8080/v1/user/signin', {
        axios.post('/v1/user/signin', {
                username: username,
                password: password
            },
            {withCredentials: true})
            .then(user => {
                setIsAuthenticated(true);
                setCurrentUser(user);
                setCurrentCenterId(user.data.centerId);
                setShowError(false);

                // anfragen, ob der Nutzer sich das erste Mal einloggt
                axios.get("/v1/user/" + user.data.id, {withCredentials: true}).then(user => {
                        if (user.data.status === 'FIRST_LOGIN') {
                            setShowLoginDialog(false);
                            setShowNewPasswordDialog(true);
                        } else if (user.data.status === 'DEFAULT') {
                            setShowLoginDialog(false);
                            setButtonLoginLogout("Logout");
                            navigate('/patientlist');
                        }
                    }
                )
            })
            .catch((e) => {
                setIsAuthenticated(false);
                setCurrentUser({});
                setShowError(true);
                setButtonLoginLogout("Login");
                console.log("login failed: ", e)
            })
    }

    const submitNewPassword = () => {
        if (newPassword1 === newPassword2) {
            axios.put('/v1/user/updatePassword', {
                username: username,
                oldPassword: oldPassword,
                newPassword: newPassword2
            }, {withCredentials: true}).then(user => {
                console.log("submit new psswrd");
            });
            setIsAuthenticated(false);
            setShowNewPasswordDialog(false);
        } else {
            setShowError(true);
        }
    }

    const cancelNewPassword = () => {
        setShowNewPasswordDialog(false);
    }

    return (
        <>
            <Dialog open={showLoginDialog} sx={dialog}>
                <>
                    <DialogTitle>{t('login.dialogTitle')}</DialogTitle>
                    <List>
                        <ListItem sx={listItemInput}>
                            <ListItemAvatar>
                                <Avatar sx={icons}>
                                    <PersonIcon/>
                                </Avatar>
                            </ListItemAvatar>
                            <InputText id={"username"}
                                       label={t('login.name')}
                                       type={"text"}
                                       variant={"standard"}
                                       placeholder={''}
                            />
                        </ListItem>

                        <ListItem sx={listItemInput}>
                            <ListItemAvatar>
                                <Avatar sx={icons}>
                                    <PasswordIcon/>
                                </Avatar>
                            </ListItemAvatar>
                            <InputText id={"password"} label={t('login.password')} type={"password"}
                                       variant={"standard"}
                                       placeholder={''}
                            />
                        </ListItem>

                        {showError &&
                        <ListItem>
                            <Alert severity="error">
                                <AlertTitle>{t('login.alertTitle')}</AlertTitle>
                                {t('login.alertText')}
                            </Alert>
                        </ListItem>
                        }

                        <ListItem sx={listItemButtons}>
                            <Button sx={cancelButton}
                                    variant="outlined"
                                    onClick={() => {
                                        setShowLoginDialog(false);
                                        setShowError(false);
                                        setButtonLoginLogout("Login")
                                    }
                                    }>{t('login.cancelButton')}
                            </Button>
                            <Button variant="outlined"
                                    sx={proceedButton}
                                    onClick={() => {
                                        signIn();
                                    }}>{t('login.proceedButton')}
                            </Button>
                        </ListItem>
                    </List>
                </>
            </Dialog>

            <Dialog open={showNewPasswordDialog}>
                <DialogTitle id="alert-dialog-title">
                    {t('login.changePasswordTitle')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('login.newPasswordText')}
                    </DialogContentText>

                    <List>
                        <ListItem sx={listItemInput}>
                            <ListItemAvatar>
                                <Avatar sx={icons}>
                                    <PasswordIcon/>
                                </Avatar>
                            </ListItemAvatar>
                            <InputText id={"oldPassword"} label={t('login.oldPassword')} type={"password"}
                                       variant={"standard"}
                                       placeholder={''}
                            />
                        </ListItem>

                        <ListItem sx={listItemInput}>
                            <ListItemAvatar>
                                <Avatar sx={icons}>
                                    <PasswordIcon/>
                                </Avatar>
                            </ListItemAvatar>
                            <InputText id={"newPassword1"}
                                       label={t('login.newPassword1')}
                                       type={"password"}
                                       variant={"standard"}
                                       placeholder={''}
                            />
                        </ListItem>

                        <ListItem sx={listItemInput}>
                            <ListItemAvatar>
                                <Avatar sx={icons}>
                                    <PasswordIcon/>
                                </Avatar>
                            </ListItemAvatar>
                            <InputText id={"newPassword2"}
                                       label={t('login.newPassword2')}
                                       type={"password"}
                                       variant={"standard"}
                                       placeholder={''}
                            />
                        </ListItem>

                        {showError &&
                        <ListItem>
                            <Alert severity="error">
                                <AlertTitle>{t('login.identicalInputAlert')}</AlertTitle>
                                {t('login.identicalInputText')}
                            </Alert>
                        </ListItem>
                        }
                    </List>
                </DialogContent>

                <DialogActions>
                    <Button onClick={cancelNewPassword}>{t('login.cancelButton')}</Button>
                    <Button onClick={submitNewPassword}>{t('login.proceedButton')}</Button>
                </DialogActions>
            </Dialog>
        </>)
}

