import React, {useEffect, useState} from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import '../clinicalEvaluation.css';
import ScoreValueSingle from "../ScoreValueSingle/ScoreValueSingle";
import CalendarInput from "../../../atoms/CalendarInput/CalendarInput";
import '../../../../App.css';
import {useTranslation} from "react-i18next";

type Props = {
    label: string,
    scoreTime: string
}

export default function ScoreValueGroup(props: Props) {
    const matches = useMediaQuery('(max-width: 1265px)');
    const [minWidth, setMinWidth] = useState("400px");
    const {t} = useTranslation();

    useEffect( () => {
        const handleResize = () => {
        }
        window.addEventListener('resize', handleResize);
        if (matches) {
            setMinWidth("100px");
        } else {
            setMinWidth("400px");
        }
    }, [matches])

    return (
        <>
            <header className="treatmentTimesHeading">{props.label}</header>
            <div className="date-container">
                <div className="score-date-label">{t('scores.date')}</div>
                <div className="date-input">
                    <CalendarInput label={t('mui.calendarDate')} inputFormat={"dd.MM.yyyy"} id={"scoreValuesDate"} scoreTime={props.scoreTime}/>
                </div>
            </div>
            <div className="date-info">{t('scores.dateinfo')}</div>

            <ScoreValueSingle label={"6 Minute Walk (in m)"} id={props.label + " 6minute"} unit={""} minWidth={minWidth}/>
            <ScoreValueSingle label={"EQ5 Score"} id={props.label + " eq5"} unit={""} minWidth={minWidth}/>
            <ScoreValueSingle label={t('scores.borgCr')} id={props.label + " borgcr"} unit={""} minWidth={minWidth}/>
            <ScoreValueSingle label={t('scores.borgRpe')} id={props.label + " borgrpe"} unit={""} minWidth={minWidth}/>
            <ScoreValueSingle label={"Montreal Cognitive Assessment Score MoCA"} id={props.label + " moca"} unit={""} minWidth={minWidth}/>
            <ScoreValueSingle label={"Epworth Sleepiness Scale"} id={props.label + " epworth"} unit={""} minWidth={minWidth}/>
            <ScoreValueSingle label={"Beck Depression Inventar Version I  BDI"} id={props.label + " beck"} unit={""} minWidth={minWidth}/>
            <ScoreValueSingle label={"Fatigue Severity Scale FSS"} id={props.label + " fss"} unit={""} minWidth={minWidth}/>

        </>
    );
}

