const checkIfAlreadyRegisteredAndReplaceWeeks = (
    // Todo: string statt id im objectToInsert (Backend)
    entryToCheck: number,
    array: [{id: number, number: number, numberOfTreatments: number, patientId: number}],
    objectToInsert: {id: number, number: number, numberOfTreatments: number, patientId: number}) => {

    const alreadyRegistered = array.find((object) => {
        return object.number === entryToCheck;
    });

    if (alreadyRegistered) {
        const index = array.findIndex(object => {
            return object.number === entryToCheck;
        });
        objectToInsert.id = array[index].id;
        array[index] = objectToInsert;
    } else {
        array.push(objectToInsert);
    }
}

function saveWeeks(id: string, event: any, axiosPatient: any) {
    if (id === "numberOfTreatmentsWeek1") {
        const week = {
            id: 0,
            number: 1,
            numberOfTreatments: Number(event.target.value),
            patientId: axiosPatient.id
        };

        checkIfAlreadyRegisteredAndReplaceWeeks(1, axiosPatient.weeks, week);
    }

    else if (id === "numberOfTreatmentsWeek2") {
        const week = {
            id: 0,
            number: 2,
            numberOfTreatments: Number(event.target.value),
            patientId: axiosPatient.id
        };
        checkIfAlreadyRegisteredAndReplaceWeeks(2, axiosPatient.weeks, week);
    }

    else if (id === "numberOfTreatmentsWeek3") {
        const week = {
            id: 0,
            number: 3,
            numberOfTreatments: Number(event.target.value),
            patientId: axiosPatient.id
        };
        checkIfAlreadyRegisteredAndReplaceWeeks(3, axiosPatient.weeks, week);
    }

    else if (id === "numberOfTreatmentsWeek4") {
        const week = {
            id: 0,
            number: 4,
            numberOfTreatments: Number(event.target.value),
            patientId: axiosPatient.id
        };
        checkIfAlreadyRegisteredAndReplaceWeeks(4, axiosPatient.weeks, week);
    }
}

export {saveWeeks};