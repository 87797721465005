import React from "react";
import MultiSelection from "../../../../molecules/MultiSelection/MultiSelection";

export default function Users() {

    const optionsUsers: string[] = [
        "Gandalf",
        "Frodo",
        "Aragorn",
    ];

    return (
        <MultiSelection
            options={optionsUsers}
            label={"Zugeordnete Nutzer"}
            id={"centerUsers"}
            labelFreeText={"Weiteren Nutzer hinzufügen"}
            idFreeText={"add new user"}/>
    );
}

