import React, {useContext} from "react";
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PinIcon from '@mui/icons-material/Pin';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Button from '@mui/material/Button';
import {dialog, cancelButton, proceedButton, icons, listItemInput, listItemButtons} from "./addNewUserStyles";
import GlobalContext from "../../../context";
import InputText from "../../../atoms/InputText/InputText";

export default function AddNewUser() {
    const {openNewUser, setOpenNewUser} = useContext(GlobalContext);

    const handleProceed = () => {
        setOpenNewUser(false);
    }

    return (
        <Dialog open={openNewUser}
                sx={dialog}>
            <DialogTitle>Neuen Nutzer hinzufügen</DialogTitle>
            <List>
                <ListItem sx={listItemInput}>
                    <ListItemAvatar>
                        <Avatar sx={icons}>
                            <PinIcon/>
                        </Avatar>
                    </ListItemAvatar>
                    <InputText id={"userId"} label={"ID des Nutzers"} type={"text"} variant={"standard"} placeholder={'test'}/>
                </ListItem>

                <ListItem sx={listItemInput}>
                    <ListItemAvatar>
                        <Avatar sx={icons}>
                            <ApartmentIcon/>
                        </Avatar>
                    </ListItemAvatar>
                    <InputText id={"userName"} label={"Name des Nutzers"} type={"text"} variant={"standard"} placeholder={'test'}/>
                </ListItem>

                <ListItem sx={listItemButtons}>
                    <Button sx={cancelButton}
                            variant="outlined"
                            onClick={handleProceed}>Abbrechen
                    </Button>
                    <Button variant="outlined"
                            sx={proceedButton}
                            onClick={handleProceed}>Weiter
                    </Button>
                </ListItem>
            </List>
        </Dialog>
    );
}

