import React, {useContext} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {useTranslation} from "react-i18next";
import GlobalContext from "../../context";
import {DialogActions, DialogContent, DialogContentText} from "@mui/material";
import axios from "axios";
import {useNavigate} from "react-router-dom";

export default function ConsentDialog() {

    const {showConsentDialog, setShowConsentDialog} = useContext(GlobalContext);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {setAxiosPatient} = useContext(GlobalContext);
    const {setNewPatient} = useContext(GlobalContext);
    const {currentCenterId} = useContext(GlobalContext);
    const {setSelectedPatientIdentification} = useContext(GlobalContext);

    const giveConsent = () => {
        //axios.get('http://localhost:8080/v1/patient/newPatient',
        axios.get('/v1/patient/newPatient',
            {withCredentials: true})
            .then(result => {
                setAxiosPatient(result.data);
                setShowConsentDialog(false);
                setNewPatient(true);

                //axios.get('http://localhost:8080/v1/patient/' + currentCenterId + '/' + result.data.identification,
                    axios.get('/v1/patient/' + currentCenterId + '/' + result.data.identification,
                    {withCredentials: true})
                    .then(result => {
                        setAxiosPatient(result.data);
                        setSelectedPatientIdentification(result.data.identification);
                        setNewPatient(false);
                        console.log("patient from backend in consent: ", result.data)
                    })
                navigate('/basicdata');
            })
    }

    const noConsent = () => {
        setShowConsentDialog(false);
    }

    return (
        <div>
            <Dialog
                open={showConsentDialog}
                onClose={giveConsent}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('patientlist.consentDialogTitle')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('newPatientConsent.consent')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={giveConsent}>{t('newPatientConsent.yes')}</Button>
                    <Button onClick={noConsent}>{t('newPatientConsent.no')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

