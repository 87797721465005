import React from "react";
import InputNumber from "../../../atoms/InputNumber/InputNumber";
import '../clinicalEvaluation.css';

type Props = {
    label: string,
    id: string,
    unit: string,
    minWidth: string,
}

export default function LabValueSingle(props: Props) {

    return (
        <InputNumber id={props.id} label={props.label}
                     unit={props.unit}
                     variant={'outlined'}
                     minWidth={props.minWidth}
                     typeOfMeasurement={'labvalue'}
        />
    );
}