import * as React from 'react';
import TextField from "@mui/material/TextField";
import {textField} from "./inputTextStyles";
import {useContext, useEffect, useState} from "react";
import GlobalContext, {defaultAxiosPatient} from "../../context";

export interface Props {
    id: string;
    placeholder: number | string | undefined,
    label: string;
    type: string;
    variant: "standard" | "filled" | "outlined";
}

export default function InputText(props: Props) {

    const [value, setValue] = useState(props.placeholder?.toString());
    const {axiosPatient} = useContext(GlobalContext);
    const {setUsername} = useContext(GlobalContext);
    const {setPassword} = useContext(GlobalContext);
    const {setOldPassword} = useContext(GlobalContext);
    const {setNewPassword1} = useContext(GlobalContext);
    const {setNewPassword2} = useContext(GlobalContext);

    // Use Default Patient from Context in case nothing field is null (e.g. if new patient is created or input mask
    // input values are incomplete
    useEffect(() => {
        if (props.id === "height" && axiosPatient.height === null) {
            axiosPatient.height = defaultAxiosPatient.height;
        } else if (props.id === "weight" && axiosPatient.weight === null) {
            axiosPatient.weight = defaultAxiosPatient.weight;
        } else if (props.id === "birthyear" && axiosPatient.yearOfBirth === null) {
            axiosPatient.yearOfBirth = defaultAxiosPatient.yearOfBirth;
        } else if (props.id === "genotype" && axiosPatient.genotype === null) {
            axiosPatient.genotype = defaultAxiosPatient.genotype;
        } else if (props.id === "otherak" && axiosPatient.otherAak === null) {
            axiosPatient.otherAak = defaultAxiosPatient.otherAak;
        } else if (props.id === "plasmaVolume" && axiosPatient.averageTreatedPlasmaVolume === null) {
            axiosPatient.averageTreatedPlasmaVolume = defaultAxiosPatient.averageTreatedPlasmaVolume;
        } else if (props.id === "other complications" && axiosPatient.otherComplicationName === null) {
            axiosPatient.otherComplicationName = defaultAxiosPatient.otherComplicationName;
        } else if (props.id === "other previous therapy" && axiosPatient.otherTherapyName === null) {
            axiosPatient.otherTherapyName = defaultAxiosPatient.otherTherapyName;
        }
    },[])

    const handleTextFieldChange = (event: any) => {
        setValue(event);
        if (props.id === "username") {
            setUsername(event);
        } else if (props.id === "password") {
            setPassword(event);
        } else if (props.id === "birthyear") {
            axiosPatient.yearOfBirth = event;
        } else if (props.id === "genotype") {
            axiosPatient.genotype = event;
        } else if (props.id === "otherak") {
            axiosPatient.otherAak = event;
        } else if (props.id === "plasmaVolume") {
            axiosPatient.averageTreatedPlasmaVolume = event;
        } else if (props.id === "height") {
            axiosPatient.height = event;
        } else if (props.id === "weight") {
            axiosPatient.weight = event;
        } else if (props.id === 'other complications') {
            axiosPatient.otherComplicationName = event;
        } else if (props.id === 'other previous therapy') {
            axiosPatient.otherTherapyName = event;
        } else if (props.id === 'oldPassword') {
            setOldPassword(event);
        } else if (props.id === 'newPassword1') {
            setNewPassword1(event);
        }  else if (props.id === 'newPassword2') {
            setNewPassword2(event);
        }
    }
    return (
        <TextField
            id={props.id}
            hiddenLabel
            label={props.label}
            variant={props.variant}
            sx={textField}
            type={props.type}
            value={value}
            onChange={(e) => handleTextFieldChange(e.target.value)}
        />
    );
}

