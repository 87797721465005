import React, {useContext} from "react";
import InputText from "../../../atoms/InputText/InputText";
import "../../../../App.css";
import {useTranslation} from "react-i18next";
import GlobalContext from "../../../context";

export default function Height() {
    const {t} = useTranslation();
    const {axiosPatient} = useContext(GlobalContext);

    return (
        <div className="inputText-general">
            <InputText
                id={"height"}
                label={t("mui.inputTextLabel.height")}
                placeholder={axiosPatient.height?.toLocaleString()}
                type={"text"}
                variant={"outlined"}
            />
        </div>
    );
}

