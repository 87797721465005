import * as React from 'react';
import {ReactNode, SyntheticEvent, useContext} from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Overview from "./Overview/Overview";
import GlobalContext from "../../context";

interface Props {
    children?: ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: Props) {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <Box>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function Admin() {
    const {activeAdminTab, setActiveAdminTab} = useContext(GlobalContext);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setActiveAdminTab(newValue);
    };

    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={activeAdminTab} onChange={handleChange}>
                    <Tab label="Übersicht Zentren" />
                    <Tab label="Übersicht Nutzer" />
                </Tabs>
            </Box>
            <TabPanel value={activeAdminTab} index={0}>
                <Overview activeTab="centers"/>
            </TabPanel>
            <TabPanel value={activeAdminTab} index={1}>
                <Overview activeTab="users"/>
            </TabPanel>
        </Box>
    );
}
