import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import TextField from "@mui/material/TextField";
import {textField} from "./inputNumberStyles";
import {saveLabValues} from "./SaveLabValues";
import './inputNumber.css';
import GlobalContext from "../../context";
import {saveScoreValues} from "./SaveScoreValues";
import {setPlaceholderScores} from "./SetPlaceholderScores";
import {setPlaceholderLabValues} from "./SetPlaceholderLabValues";
import {useTranslation} from "react-i18next";

export interface Props {
    id: string;
    label: string;
    variant: "standard" | "filled" | "outlined";
    unit: string;
    minWidth: string;
    typeOfMeasurement?: string,
}

export default function InputNumber(props: Props) {
    const [value, setValue] = useState(undefined);
    const {axiosPatient} = useContext(GlobalContext);
    const {currentPatient} = useContext(GlobalContext);
    const {t} = useTranslation();

    useEffect(() => {
        if (props.typeOfMeasurement === "score") {
            if (setPlaceholderScores(props.id, props.label, axiosPatient, t)) {
                // @ts-ignore
                setValue(setPlaceholderScores(props.id, props.label, axiosPatient, t));
            }
        }
        if (props.typeOfMeasurement === "labvalue") {
            if (setPlaceholderLabValues(props.id, props.label, axiosPatient, t)) {
                // @ts-ignore
                setValue(setPlaceholderLabValues(props.id, props.label, axiosPatient, t));
            }
        }

    },[props.label, props.id, axiosPatient, props.typeOfMeasurement])


    const handleChange = (event: any) => {
        setValue(event);

        if (props.typeOfMeasurement === "labvalue") {
            saveLabValues(props.id, event, axiosPatient, currentPatient);
        }

        if (props.typeOfMeasurement === "score") {
            saveScoreValues(props.id, event, axiosPatient, currentPatient)
        }
    }

    return (
        <div className="inputNumber">
            <div className="inputNumberLabel" style={{minWidth: props.minWidth}}>{props.label}</div>
            <div className="inputNumber-textfield-unit">
                <TextField
                    className="inputNumberTextfield"
                    id={props.id}
                    variant={props.variant} sx={textField}
                    value={value}
                    type={"number"}
                    onChange={(e) => handleChange(e.target.value)}
                />
                <div className="inputNumberUnit">{props.unit}</div>
            </div>
        </div>

    );
}

