import React, {useContext, useEffect} from "react";
import {useNavigate} from 'react-router-dom';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import GlobalContext from "../../../context";
import './overview.css';
import {newCenterButton} from "./overviewStyles";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import AddNewUser from "../AddNewUser/AddNewUser";
import AddNewCenter from "../AddNewCenter/AddNewCenter";

interface Props {
    activeTab: string;
}

export default function Overview(props: Props) {

    const {openNewUser, setOpenNewUser} = useContext(GlobalContext);
    const {openNewCenter, setOpenNewCenter} = useContext(GlobalContext);

    const {setSelectedCenterIdAdmin} = useContext(GlobalContext);
    const {setSelectedUserId} = useContext(GlobalContext);

    const navigate = useNavigate();

    useEffect(()=> {
        console.log("currently active: ", props.activeTab);
    })

    const columnsCenters: GridColDef[] = [
        {
            field: 'id',
            headerName: "Zentrums-ID",
            type: 'number',
            flex: 1,
            minWidth: 100
        },
        {
            field: 'name',
            headerName: "Name des Zentrums",
            type: 'string',
            flex: 2,
            minWidth: 100
        },
        {
            field: 'centerUsers',
            headerName: "Zugeordnete Nutzer",
            type: "date",
            flex: 2,
            minWidth: 100
        },
    ];

    const rowsCenters = [
        {id: 1, name: "123",  centerUsers: 'S. Jenke, C.Peter'},
        {id: 2, name: "456", centerUsers: 'N.Selke'},
        {id: 3, name: "636",  centerUsers: 'C.Zilske'},
        {id: 4, name: "817",  centerUsers: 'A. Kaul'},
        {id: 5, name: "393",  centerUsers: 'S.Jenke, C.Zilske'},
        {id: 6, name: "3993",  centerUsers: 'S.Jenke, N. Selke'},
        {id: 7, name: "333", centerUsers: 'C.Peter, C.Koska'},
        {id: 8, name: "222", centerUsers: 'R.Magnus'},
    ];

    const columnsUsers: GridColDef[] = [
        {
            field: 'id',
            headerName: "Nutzer ID",
            type: 'number',
            flex: 1,
            minWidth: 100
        },
        {
            field: 'centerUserId',
            headerName: "Zentrum ID",
            type: "string",
            flex: 2,
            minWidth: 100
        },
        {
            field: 'role',
            headerName: "Rolle",
            type: "string",
            flex: 2,
            minWidth: 100
        },
        {
            field: 'name',
            headerName: "Name",
            type: "string",
            flex: 2,
            minWidth: 100
        },
    ];

    const rowsUsers = [
        {id: 1, centerUserId: '14343', role: 'user', name: "Gandalf der Graue"},
        {id: 2, centerUserId: '17464', role: 'monitor', name: "Palantir von Minas Ithil"},
        {id: 3, centerUserId: '10283', role: 'user', name: "Melkor Morgoth"},
        {id: 4, centerUserId: '12644', role: 'monitor', name: "Frodo Beutlin"},
        {id: 5, centerUserId: '14567', role: 'user', name: "Samweis Gamdschie"},
        {id: 6, centerUserId: '17543', role: 'monitor', name: "Der alte Ohm"},
        {id: 7, centerUserId: '16155', role: 'monitor', name: "Pippin der Lange"},
        {id: 8, centerUserId: '16543', role: 'user', name: "Der Eine Ring"},
    ];

    const handleOnCellClick = (params: any) => {
        if (props.activeTab === "centers") {
            if (params.id) {
                navigate('/center');
                setSelectedCenterIdAdmin(params.id);
            }
        } else {
            if (params.id) {
                navigate('/user');
                setSelectedUserId(params.id);
            }
        }

    };

    const createNew = () => {
        //Todo sobald Backend fertig
        if (props.activeTab === "centers") {
            setOpenNewCenter(true);
        } else {
            setOpenNewUser(true);

        }
    };

    return (
        <div className="overviewCenters-wrapper">
            <div className="overviewCenters-header-text">
                {props.activeTab === "centers" &&
                    <header className="overviewCenters-header">Übersicht aller Zentren</header>
                }
                {props.activeTab === "users" &&
                    <header className="overviewCenters-header">Übersicht aller Nutzer</header>
                }
                <div className="overviewCenters-grid">
                    {props.activeTab === "centers" &&
                    <DataGrid
                        rows={rowsCenters}
                        columns={columnsCenters}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        onCellClick={handleOnCellClick}
                    />
                    }
                    {props.activeTab === "users" &&
                    <DataGrid
                        rows={rowsUsers}
                        columns={columnsUsers}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        onCellClick={handleOnCellClick}
                    />
                    }
                    <div>
                        <Button
                            variant="contained"
                            sx={newCenterButton}
                            onClick={createNew}
                            startIcon={<AddIcon/>}
                        >
                            Hinzufügen
                        </Button>
                    </div>
                </div>
                {openNewUser &&
                    <AddNewUser/>
                }
                {openNewCenter &&
                    <AddNewCenter/>
                }
            </div>
        </div>
    );
}