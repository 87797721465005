const floatingButtonPatientList = {
    margin: 0,
    top: 'auto',
    right: 'auto',
    bottom: 20,
    left: 20,
    position: 'fixed',
    backgroundColor: "#2A57A5",
    color: "white",
    "&:hover": {
        backgroundColor: "#0288d1",
    },
};

const floatingButtonSave = {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    backgroundColor: "#2A57A5",
    color: "white",
    "&:hover": {
        backgroundColor: "#0288d1",
    },
};

export { floatingButtonSave, floatingButtonPatientList };