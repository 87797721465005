import React from "react";

export default function Contact() {
    return (
        <div className="company">
            <h1 className="legalNotice-header">Kontakt</h1>
            <span>European Group - International Society for Apheresis</span>
            <span>Office:</span>
            <span>Schliemannstr. 2</span>
            <span>18059 Rostock, Germany</span>
            <span>Email: info@e-isfa.eu</span>
        </div>
    );
}

