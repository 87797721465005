import React from "react";
import AutoAntibody from "../AutoAntibody/AutoAntibody";

export default function AutoAntibodies() {

    return (
        <>
            <AutoAntibody label={"Beta1-AKK"} id={"beta1akk"}/>
            <AutoAntibody label={"M2-AAK"} id={"m2akk"}/>
            <AutoAntibody label={"Alpha1-AAK"} id={"alpha1akk"}/>
            <AutoAntibody label={"AT1-AAK/ACE2-AAK"} id={"at1ace2akk"}/>
            <AutoAntibody label={"ET-AAK"} id={"etaak"}/>
            <AutoAntibody label={"Beta2-AAK"} id={"beta2akk"}/>
            <AutoAntibody label={"PAR-AAK"} id={"paraak"}/>
        </>

    );
}

