import React from "react";
import Week from "../Week/Week";
import {useTranslation} from "react-i18next";

export default function Weeks() {
    const {t} = useTranslation();

    return (
        <>
            <Week label={t("mui.dropdownSelectionLabel.numberOfTreatmentsWeek1")}
                  id={"numberOfTreatmentsWeek1"}/>
            <Week label={t("mui.dropdownSelectionLabel.numberOfTreatmentsWeek2")}
                  id={"numberOfTreatmentsWeek2"}/>
            <Week label={t("mui.dropdownSelectionLabel.numberOfTreatmentsWeek3")}
                  id={"numberOfTreatmentsWeek3"}/>
            <Week label={t("mui.dropdownSelectionLabel.numberOfTreatmentsWeek4")}
                  id={"numberOfTreatmentsWeek4"}/>
        </>

    );
}

