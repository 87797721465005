const textField = {
    //width: "90%",

    /*"& .MuiDialog-container": {
        "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",
        },
    },*/
}



export { textField };