import React, {useContext} from "react";
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PinIcon from '@mui/icons-material/Pin';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Button from '@mui/material/Button';
import {dialog, cancelButton, proceedButton, icons, listItemInput, listItemButtons} from "./addNewCenterStyles";
import GlobalContext from "../../../context";
import InputText from "../../../atoms/InputText/InputText";

export default function AddNewCenter() {
    const {openNewCenter, setOpenNewCenter} = useContext(GlobalContext);

    const handleProceed = () => {
        setOpenNewCenter(false);
    }

    return (
        <Dialog open={openNewCenter}
                sx={dialog}>
            <DialogTitle>Neues Zentrum hinzufügen</DialogTitle>
            <List>
                <ListItem sx={listItemInput}>
                    <ListItemAvatar>
                        <Avatar sx={icons}>
                            <PinIcon/>
                        </Avatar>
                    </ListItemAvatar>
                    <InputText id={"centerId"} label={"ID des Zentrums"} type={"text"} variant={"standard"} placeholder={'test'}/>
                </ListItem>

                <ListItem sx={listItemInput}>
                    <ListItemAvatar>
                        <Avatar sx={icons}>
                            <ApartmentIcon/>
                        </Avatar>
                    </ListItemAvatar>
                    <InputText id={"centerName"} label={"Name des Zentrums"} type={"text"} variant={"standard"} placeholder={'test'}/>
                </ListItem>

                <ListItem sx={listItemButtons}>
                    <Button sx={cancelButton}
                            variant="outlined"
                            onClick={handleProceed}>Abbrechen
                    </Button>
                    <Button variant="outlined"
                            sx={proceedButton}
                            onClick={handleProceed}>Weiter
                    </Button>
                </ListItem>
            </List>
        </Dialog>
    );
}

