import React, {useContext, useEffect, useState} from "react";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import FormControl from "@mui/material/FormControl";
import InputText from "../../atoms/InputText/InputText";
import "../../../App.css";
import {multiSelectionAlignInput, multiSelectionComplications} from "./multiSelectionStyles";
import GlobalContext, {defaultAxiosPatient} from "../../context";
import {useTranslation} from "react-i18next";

type Props = {
    options: string[],
    //Todo: Make non-optional
    placeholder?:string,
    label: string,
    labelFreeText: string,
    id: string,
    idFreeText: string,
    typeOfMeasurement?: string,
}

export default function MultiSelection(props: Props) {

    // Important: In useState Hook, always explicitly type when working with arrays/objects, because Typescript infering
    // is not always reliable, as is the case here (placeholder value not shown when using just "useState([""])"
    // Wrong: const [selectedComplication, setSelectedComplication] = useState([""]);
    // Correct:
    const [selectedCheckbox, setSelectedCheckbox] = useState<string[]>([]);
    const [showOther, setShowOther] = useState(false);
    const {axiosPatient} = useContext(GlobalContext);
    const {t} = useTranslation();

    useEffect(() => {
        if (props.placeholder) {
            setSelectedCheckbox( [props.placeholder]);
        }
    }, [props.placeholder]);

    // Use Default Patient from Context in case nothing field is null (e.g. if new patient is created or input mask
    // input values are incomplete
    useEffect(() => {
        if (props.id === "previousTherapy" && axiosPatient.previousTherapy === null) {
            axiosPatient.previousTherapy = defaultAxiosPatient.previousTherapy;
        } else if (props.id === "complications" && axiosPatient.complication === null) {
            axiosPatient.complication = defaultAxiosPatient.complication;
        }
    },[])

    const handleChangeComplications = (event: any) => {
        const value = event.target.value;

        if (value.includes('Andere')) {
            setShowOther(true);
        } else {
            setShowOther(false);
        }
        setSelectedCheckbox(value);

        // save to patient object
        if (props.id === "complications") {
            let objectToSave = {
                cardial: false,
                id: 0,
                neurological: false,
                otherComplication: false,
                otherComplicationName: "",
                otherOrganManifestation: false,
                patientId: 0,
                pulmonary: false,
                tromboembolic: false
            }

            value.forEach(function (item: any) {
                if (item === t('mui.multiSelection.complicationsOptions.thromboembolic')) {
                    objectToSave.tromboembolic = true;
                } else if (item === t('mui.multiSelection.complicationsOptions.pulmonary')) {
                    objectToSave.pulmonary = true;
                } else if (item === t('mui.multiSelection.complicationsOptions.cardial')) {
                    objectToSave.cardial = true;
                } else if (item === t('mui.multiSelection.complicationsOptions.neurological')) {
                    objectToSave.neurological = true;
                } else if (item === t('mui.multiSelection.complicationsOptions.furtherManifestations')) {
                    objectToSave.otherOrganManifestation = true;
                } else if (item === t('mui.multiSelection.complicationsOptions.other')) {
                    objectToSave.otherComplication = true;
                }
            });
            objectToSave.id = axiosPatient.complication.id;
            axiosPatient.complication = objectToSave;
        }
        if (props.id === "previousTherapy") {
            let objectToSave = {
                cardiology: false,
                hematology: false,
                id: 0,
                neurology: false,
                otherTherapy: false,
                psychology: false,
                pneumology: false,
            }

            value.forEach(function (item: any) {
                if (item === t('singleInformation.optionsPreviousTherapies.cardiology')) {
                    objectToSave.cardiology = true;
                } else if (item === t('singleInformation.optionsPreviousTherapies.neurology')) {
                    objectToSave.neurology = true;
                } else if (item === t('singleInformation.optionsPreviousTherapies.psychology')) {
                    objectToSave.psychology = true;
                } else if (item === t('singleInformation.optionsPreviousTherapies.hematology')) {
                    objectToSave.hematology = true;
                } else if (item === t('singleInformation.optionsPreviousTherapies.pneumology')) {
                    objectToSave.pneumology = true;
                }
            });
            objectToSave.id = axiosPatient.previousTherapy.id;
            axiosPatient.previousTherapy = objectToSave;
        }
    };

    const showPlaceholderValues = () => {
        if (props.typeOfMeasurement === 'complications') {
            let placeholderValues = []
            for (let key in axiosPatient.complication) {
                // @ts-ignore
                if (axiosPatient.complication[key] === true) {
                    if (key === 'tromboembolic') {
                        key = t("mui.multiSelection.complicationsOptions.thromboembolic");
                    } else if (key === 'cardial') {
                        key = t("mui.multiSelection.complicationsOptions.cardial");
                    } else if (key === 'pulmonary') {
                        key = t("mui.multiSelection.complicationsOptions.pulmonary");
                    } else if (key === 'neurological') {
                        key =  t("mui.multiSelection.complicationsOptions.neurological");
                    } else if (key === 'otherOrganManifestation') {
                        key = t("mui.multiSelection.complicationsOptions.furtherManifestations");
                    } else if (key === 'otherComplication') {

                        key = t("mui.multiSelection.complicationsOptions.other");
                    }
                    placeholderValues.push(key);
                    setSelectedCheckbox(placeholderValues);
                }
            }
        } else if (props.typeOfMeasurement === 'previousTherapies') {
            let placeholderValues = []
            for (let key in axiosPatient.previousTherapy) {
                // @ts-ignore
                if (axiosPatient.previousTherapy[key] === true) {
                    if (key === 'cardiology') {
                        key = t("singleInformation.optionsPreviousTherapies.cardiology");
                    } else if (key === 'hematology') {
                        key = t("singleInformation.optionsPreviousTherapies.hematology");
                    } else if (key === 'neurology') {
                        key = t("singleInformation.optionsPreviousTherapies.neurology");
                    } else if (key === 'psychology') {
                        key = t("singleInformation.optionsPreviousTherapies.psychology");
                    } else if (key === 'pneumology') {
                        key = t("singleInformation.optionsPreviousTherapies.pneumology");
                    } else if (key === 'otherTherapy') {
                        key = t("singleInformation.optionsPreviousTherapies.other");
                    }
                    placeholderValues.push(key);
                    setSelectedCheckbox(placeholderValues);
                }
            }
        }
    }

    return (
        <FormControl sx={multiSelectionComplications}>
            <InputLabel id={props.id}>{props.label}</InputLabel>
            <Select
                sx={multiSelectionAlignInput}
                labelId={props.id}
                id={props.id}
                value={selectedCheckbox}
                label={props.label}
                multiple
                onChange={handleChangeComplications}
                onOpen={showPlaceholderValues}
                renderValue={(selected) => selected.join(", ")}
            >
                {props.options.map((option) => (
                    <MenuItem key={option} value={option}>
                        <ListItemIcon>
                            <Checkbox checked={selectedCheckbox.indexOf(option) > -1}/>
                        </ListItemIcon>
                        <ListItemText primary={option}/>
                    </MenuItem>
                ))}
            </Select>
            {showOther &&
            <div className="inputText-other">
                <InputText id={props.idFreeText} label={props.labelFreeText} type={"text"} variant={"outlined"} placeholder={''}/>
            </div>
            }
        </FormControl>
    );
}

