import React from "react";
import LabValueSingle from "../LabValueSingle/LabValueSingle";
import CalendarInput from "../../../atoms/CalendarInput/CalendarInput";
import '../clinicalEvaluation.css';
import '../../../../App.css';
import {useTranslation} from "react-i18next";

type Props = {
    label: string,
    labId?: string,
    labTime: string,
}

export default function LabValueGroup(props: Props) {
    const {t} = useTranslation();

    return (
        <>
            <header className="treatmentTimesHeading">{props.label}</header>

            {props.labId &&
                <div className="labvalue-id">
                    {t('clinicalEvaluation.labId')}: {props.labId}
                </div>
            }
            <div className="clinicalEvaluation-labvalues">

                <div className="date-container">
                    <div className="labvalue-date-label">{t('clinicalEvaluation.labDate')}</div>
                    <div className="date-input">
                        <CalendarInput
                            label={t('mui.calendarDate')}
                            inputFormat={"dd.MM.yyyy"}
                            id={"labValuesDate"}
                            labTime={props.labTime}
                        />
                    </div>
                </div>

                <LabValueSingle label={"Fibrinogen"} id={props.label + " fibrinogen"} unit={"g/l"} minWidth={"200px"}/>
                <LabValueSingle label={"IgG"} id={props.label + " igg"} unit={"mg/dl"} minWidth={"200px"}/>
                <LabValueSingle label={"IgM"} id={props.label + " igm"}  unit={"mg/dl"} minWidth={"200px"}/>
                <LabValueSingle label={"IgA"} id={props.label + " iga"}  unit={"mg/dl"} minWidth={"200px"}/>
                <LabValueSingle label={"IgE"} id={props.label + " ige"}  unit={"mg/dl"} minWidth={"200px"}/>
            </div>

            <a className="unitCalculator" href="https://www.labor-limbach.de/laborrechner/labor-rechner/einheiten-umrechner/">{t('clinicalEvaluation.unitConverter')}</a>.
        </>
    );
}

