import React from "react";
import InputNumber from "../../../atoms/InputNumber/InputNumber";
import '../clinicalEvaluation.css';

type Props = {
    label: string,
    id: string,
    unit: string,
    minWidth: string,
}

export default function ScoreValueSingle(props: Props) {

    return (
        <div className="score-value-container">
            <InputNumber id={props.id}
                         label={props.label}
                         unit={props.unit}
                         minWidth={props.minWidth}
                         variant={'outlined'}
                         typeOfMeasurement={'score'}
            />
        </div>
    );
}