import React, {useContext, useState} from "react";
import CheckboxLabeled from "../../atoms/CheckboxLabeled/CheckboxLabeled";
import LabValueGroup from "./LabValueGroup/LabValueGroup";
import ScoreValueGroup from "./ScoreValueGroup/ScoreValueGroup";
import SymptomsAfterCovidGroup from "./SymptomsAfterCovidGroup/SymptomsAfterCovidGroup";
import './clinicalEvaluation.css';
import '../../../App.css';
import {useTranslation} from "react-i18next";
import GlobalContext from "../../context";

export default function ClinicalEvaluation() {

    const [labValuesApprovedByPatient, setLabValuesApprovedByPatient] = useState(false);
    const {t} = useTranslation();
    const {axiosPatient} = useContext(GlobalContext);

    return (
        <div className="clinicalEvaluation-inputMask-container">
            <header className="inputMask-main-header">{t('clinicalEvaluation.title')}</header>
            <header className="inputMask-subheading">{t('clinicalEvaluation.labvalues')}</header>

            <CheckboxLabeled labValuesApprovedByPatient={labValuesApprovedByPatient}
                             change={setLabValuesApprovedByPatient}
                             id={"labValuesApprovedByPatient"}
                             typeOfMeasurement={"approvalBioSampling"}
                             label={t('clinicalEvaluation.approvalBioSampling')}
            />
            {/*Todo: labId from Backend, incl. i8n*/}
            <div className="labvalues-container">
                <LabValueGroup
                    label={t('timings.beforeTreatment')}
                    labId={axiosPatient.bioSampleBeforeTreatment}
                    labTime={'beforeTreatment'}
                />
                <LabValueGroup
                    label={t('timings.afterLastTreatment')}
                    labId={axiosPatient.bioSampleAfterTreatment}
                    labTime={'afterLastTreatment'}
                />
                <LabValueGroup
                    label={t('timings.afterOneMonth')}
                    labTime={'afterOneMonth'}
                />
                <LabValueGroup
                    label={t('timings.afterThreeMonths')}
                    labTime={'afterThreeMonths'}
                />
            </div>

            <header className="inputMask-subheading">Scores</header>
            <ScoreValueGroup
                label={t('timings.beforeTreatment')}
                scoreTime={'beforeTreatment'}
            />
            <ScoreValueGroup
                label={t('timings.afterLastTreatment')}
                scoreTime={'afterLastTreatment'}
            />
            <ScoreValueGroup
                label={t('timings.afterOneMonth')}
                scoreTime={'afterOneMonth'}
            />
            <ScoreValueGroup
                label={t('timings.afterThreeMonths')}
                scoreTime={'afterThreeMonths'}
            />


            <header className="inputMask-subheading">{t('clinicalEvaluation.symptomsAfterCovid')}
            </header>
            <SymptomsAfterCovidGroup
                label={t('timings.beforeTreatment')}
            />
            <SymptomsAfterCovidGroup
                label={t('timings.afterLastTreatment')}
            />
            <SymptomsAfterCovidGroup
                label={t('timings.afterOneMonth')}
            />
            <SymptomsAfterCovidGroup
                label={t('timings.afterThreeMonths')}
            />

        </div>
    );
}

