import React, {useContext, useEffect, useState} from "react";
import MultiSelection from "../../../molecules/MultiSelection/MultiSelection";
import "../basicData.css";
import {useTranslation} from "react-i18next";
import GlobalContext from "../../../context";

export default function Complications() {
    const {t} = useTranslation();
    const {axiosPatient} = useContext(GlobalContext);
    const [placeholder, setPlaceholder] = useState("");

    const options: string[] = [
        t("mui.multiSelection.complicationsOptions.thromboembolic"),
        t("mui.multiSelection.complicationsOptions.pulmonary"),
        t("mui.multiSelection.complicationsOptions.cardial"),
        t("mui.multiSelection.complicationsOptions.neurological"),
        t("mui.multiSelection.complicationsOptions.furtherManifestations"),
        t("mui.multiSelection.complicationsOptions.other"),
    ];

    useEffect(() => {

        let listedComplications = axiosPatient.complication;
        let listedComplicationsToShow = "";

        for (const [key, val] of Object.entries(listedComplications)) {
            if (val === true) {
                let translatedKey = '';
                if (key === 'tromboembolic') {
                    translatedKey =  t("mui.multiSelection.complicationsOptions.thromboembolic");
                    listedComplicationsToShow += "" + translatedKey + " ";
                } else if (key === 'pulmonary') {
                    translatedKey = t("mui.multiSelection.complicationsOptions.pulmonary");
                    listedComplicationsToShow += "" + translatedKey + " ";
                } else if (key === 'neurological') {
                    translatedKey = t("mui.multiSelection.complicationsOptions.neurological");
                    listedComplicationsToShow += "" + translatedKey + " ";
                } else if (key === 'cardial') {
                    translatedKey =  t("mui.multiSelection.complicationsOptions.cardial");
                    listedComplicationsToShow += "" + translatedKey + " ";
                } else if (key === 'otherOrganManifestation') {
                    translatedKey = t("mui.multiSelection.complicationsOptions.furtherManifestations");
                    listedComplicationsToShow += "" + translatedKey + " ";
                } else {
                    listedComplicationsToShow += "" + key + " ";
                }
                setPlaceholder(listedComplicationsToShow);
            }
        }
    },[t])

    return (
        <MultiSelection
            options={options}
            placeholder={placeholder}
            label={t("mui.multiSelection.complications")}
            id={"complications"}
            labelFreeText={t("mui.multiSelection.complicationsOther")}
            idFreeText={"other complications"}
            typeOfMeasurement={'complications'}
        />
    );
}

