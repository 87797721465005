interface ScoreValues {
    bdi: number | undefined;
    borgCr: number | undefined;
    borgRpe: number | undefined;
    canadianScore: number | undefined;
    epworthSleepinessScale: number | undefined;
    eq5Score: number | undefined;
    fss: number | undefined;
    id: number | undefined;
    moca: number | undefined;
    patientId: number;
    pointOfMeasurement: string;
    measurementDate: Date | undefined;
    sixMinuteWalk: number | undefined;
}

function saveScoreValues(id: string, event: any, axiosPatient: any, currentPatient: any) {

    const alreadyRegisteredBefore = (objectToCheck: any, timeOfMeasurement: string) => {
        return axiosPatient.scores.find((objectToCheck: { pointOfMeasurement: string; }) => {
            return objectToCheck.pointOfMeasurement === timeOfMeasurement;
        });
    }

    const checkIfAlreadyRegisteredAndReplaceOrAddNew = (pointOfMeasurement: string, scoreValue: string, measurementDate: Date | undefined) => {

        let scoreValues: ScoreValues = {
            bdi: undefined,
            borgCr: undefined,
            borgRpe: undefined,
            canadianScore: undefined,
            epworthSleepinessScale: undefined,
            eq5Score: undefined,
            fss: undefined,
            id: undefined,
            moca: undefined,
            patientId: axiosPatient.id,
            pointOfMeasurement: pointOfMeasurement,
            measurementDate: measurementDate,
            sixMinuteWalk: undefined
        }

        switch (scoreValue) {
            case "6minute":
                scoreValues.sixMinuteWalk = Number(event);
                if (alreadyRegisteredBefore(scoreValues, pointOfMeasurement)) {
                    let index = axiosPatient.scores.findIndex((object: { pointOfMeasurement: string; }) => object.pointOfMeasurement === pointOfMeasurement)
                    axiosPatient.scores[index].sixMinuteWalk = Number(event);
                } else {
                    axiosPatient.scores.push(scoreValues);
                }
                break;
            case "eq5":
                scoreValues.eq5Score = Number(event);
                if (alreadyRegisteredBefore(scoreValues, pointOfMeasurement)) {
                    let index = axiosPatient.scores.findIndex((object: { pointOfMeasurement: string; }) => object.pointOfMeasurement === pointOfMeasurement)
                    axiosPatient.scores[index].eq5Score = Number(event);
                } else {
                    axiosPatient.scores.push(scoreValues);
                }
                break;
            case "borgcr":
                scoreValues.borgCr = Number(event);
                if (alreadyRegisteredBefore(scoreValues, pointOfMeasurement)) {
                    let index = axiosPatient.scores.findIndex((object: { pointOfMeasurement: string; }) => object.pointOfMeasurement === pointOfMeasurement)
                    axiosPatient.scores[index].borgCr = Number(event);
                } else {
                    axiosPatient.scores.push(scoreValues);
                }
                break;
            case "borgrpe":
                scoreValues.borgRpe = Number(event);
                if (alreadyRegisteredBefore(scoreValues, pointOfMeasurement)) {
                    let index = axiosPatient.scores.findIndex((object: { pointOfMeasurement: string; }) => object.pointOfMeasurement === pointOfMeasurement)
                    axiosPatient.scores[index].borgRpe = Number(event);
                } else {
                    axiosPatient.scores.push(scoreValues);
                }
                break;
            case "moca":
                scoreValues.moca = Number(event);
                if (alreadyRegisteredBefore(scoreValues, pointOfMeasurement)) {
                    let index = axiosPatient.scores.findIndex((object: { pointOfMeasurement: string; }) => object.pointOfMeasurement === pointOfMeasurement)
                    axiosPatient.scores[index].moca = Number(event);
                } else {
                    axiosPatient.scores.push(scoreValues);
                }
                break;
            case "epworth":
                scoreValues.epworthSleepinessScale = Number(event);
                if (alreadyRegisteredBefore(scoreValues, pointOfMeasurement)) {
                    let index = axiosPatient.scores.findIndex((object: { pointOfMeasurement: string; }) => object.pointOfMeasurement === pointOfMeasurement)
                    axiosPatient.scores[index].epworthSleepinessScale = Number(event);
                } else {
                    axiosPatient.scores.push(scoreValues);
                }
                break;
            case "beck":
                scoreValues.bdi = Number(event);
                if (alreadyRegisteredBefore(scoreValues, pointOfMeasurement)) {
                    let index = axiosPatient.scores.findIndex((object: { pointOfMeasurement: string; }) => object.pointOfMeasurement === pointOfMeasurement)
                    axiosPatient.scores[index].bdi = Number(event);
                } else {
                    axiosPatient.scores.push(scoreValues);
                }
                break;
            case "fss":
                scoreValues.fss = Number(event);
                if (alreadyRegisteredBefore(scoreValues, pointOfMeasurement)) {
                    let index = axiosPatient.scores.findIndex((object: { pointOfMeasurement: string; }) => object.pointOfMeasurement === pointOfMeasurement)
                    axiosPatient.scores[index].fss = Number(event);
                } else {
                    axiosPatient.scores.push(scoreValues);
                }
                break;
        }
    }

    if (id.includes("Vor der Behandlung")) {
        const scoreValueToCheck = id.replace("Vor der Behandlung ", '');

        switch (scoreValueToCheck) {
            case "6minute":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("beforeTreatment", "6minute", currentPatient.scoreDateBefore);
                break;
            case "eq5":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("beforeTreatment", "eq5", currentPatient.scoreDateBefore);
                break;
            case "borgcr":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("beforeTreatment", "borgcr", currentPatient.scoreDateBefore);
                break;
            case "borgrpe":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("beforeTreatment", "borgrpe", currentPatient.scoreDateBefore);
                break;
            case "moca":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("beforeTreatment", "moca", currentPatient.scoreDateBefore);
                break;
            case "epworth":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("beforeTreatment", "epworth", currentPatient.scoreDateBefore);
                break;
            case "beck":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("beforeTreatment", "beck", currentPatient.scoreDateBefore);
                break;
            case "fss":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("beforeTreatment", "fss", currentPatient.scoreDateBefore);
                break;
        }
    }

    else if (id.includes("Nach der letzten Sitzung")) {
        const scoreValueToCheck = id.replace("Nach der letzten Sitzung ", '');

        switch (scoreValueToCheck) {
            case "6minute":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterLastTreatment", "6minute", currentPatient.scoreDateAfter);
                break;
            case "eq5":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterLastTreatment", "eq5", currentPatient.scoreDateAfter);
                break;
            case "borgcr":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterLastTreatment", "borgcr", currentPatient.scoreDateAfter);
                break;
            case "borgrpe":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterLastTreatment", "borgrpe", currentPatient.scoreDateAfter);
                break;
            case "moca":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterLastTreatment", "moca", currentPatient.scoreDateAfter);
                break;
            case "epworth":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterLastTreatment", "epworth", currentPatient.scoreDateAfter);
                break;
            case "beck":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterLastTreatment", "beck", currentPatient.scoreDateAfter);
                break;
            case "fss":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterLastTreatment", "fss", currentPatient.scoreDateAfter);
                break;
        }
    }

    else if (id.includes("Nach 1 Monat")) {
        const scoreValueToCheck = id.replace("Nach 1 Monat ", '');

        switch (scoreValueToCheck) {
            case "6minute":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterOneMonth", "6minute", currentPatient.scoreDateOneMonth);
                break;
            case "eq5":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterOneMonth", "eq5", currentPatient.scoreDateOneMonth);
                break;
            case "borgcr":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterOneMonth", "borgcr", currentPatient.scoreDateOneMonth);
                break;
            case "borgrpe":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterOneMonth", "borgrpe", currentPatient.scoreDateOneMonth);
                break;
            case "moca":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterOneMonth", "moca", currentPatient.scoreDateOneMonth);
                break;
            case "epworth":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterOneMonth", "epworth", currentPatient.scoreDateOneMonth);
                break;
            case "beck":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterOneMonth", "beck", currentPatient.scoreDateOneMonth);
                break;
            case "fss":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterOneMonth", "fss", currentPatient.scoreDateOneMonth);
                break;
        }
    }

    else if (id.includes("Nach 3 Monaten")) {
        const scoreValueToCheck = id.replace("Nach 3 Monaten ", '');

        switch (scoreValueToCheck) {
            case "6minute":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterThreeMonths", "6minute", currentPatient.scoreDateThreeMonths);
                break;
            case "eq5":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterThreeMonths", "eq5", currentPatient.scoreDateThreeMonths);
                break;
            case "borgcr":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterThreeMonths", "borgcr", currentPatient.scoreDateThreeMonths);
                break;
            case "borgrpe":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterThreeMonths", "borgrpe", currentPatient.scoreDateThreeMonths);
                break;
            case "moca":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterThreeMonths", "moca", currentPatient.scoreDateThreeMonths);
                break;
            case "epworth":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterThreeMonths", "epworth", currentPatient.scoreDateThreeMonths);
                break;
            case "beck":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterThreeMonths", "beck", currentPatient.scoreDateThreeMonths);
                break;
            case "fss":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterThreeMonths", "fss", currentPatient.scoreDateThreeMonths);
                break;
        }
    }
}

export {saveScoreValues};
