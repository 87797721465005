function saveSymptomsValues(id: string, event: any, axiosPatient: any, isChecked: boolean, t:any) {

    const alreadyRegisteredBefore = (objectToCheck: any, timeOfMeasurement: string) => {
        return axiosPatient.symptoms.find((objectToCheck: { pointOfMeasurement: string; }) => {
            return objectToCheck.pointOfMeasurement === timeOfMeasurement;
        });
    }

    const checkIfAlreadyRegisteredAndReplaceOrAddNew = (pointOfMeasurement: string, symptomsValue: string) => {

        let object = {
            fatigue: false,
            fever: false,
            fluLikeSymptoms: false,
            generalizedPain: false,
            id: 0,
            patientId: axiosPatient.id,
            jointPain: false,
            muscularPain: false,
            pointOfMeasurement: pointOfMeasurement,
            weightLoss: false
        }

        switch (symptomsValue) {
            case "weightloss":
                object.weightLoss = isChecked;
                if (alreadyRegisteredBefore(object, pointOfMeasurement)) {
                    let index = axiosPatient.symptoms.findIndex((object: { pointOfMeasurement: string; }) => object.pointOfMeasurement === pointOfMeasurement)
                    axiosPatient.symptoms[index].weightLoss = isChecked;
                } else {
                    axiosPatient.symptoms.push(object);
                }
                break;

            case "muscleache":
                object.muscularPain = isChecked;
                if (alreadyRegisteredBefore(object, pointOfMeasurement)) {
                    let index = axiosPatient.symptoms.findIndex((object: { pointOfMeasurement: string; }) => object.pointOfMeasurement === pointOfMeasurement)
                    axiosPatient.symptoms[index].muscularPain = isChecked;
                } else {
                    axiosPatient.symptoms.push(object);
                }
                break;
            case "generalpain":
                object.generalizedPain = isChecked;
                if (alreadyRegisteredBefore(object, pointOfMeasurement)) {
                    let index = axiosPatient.symptoms.findIndex((object: { pointOfMeasurement: string; }) => object.pointOfMeasurement === pointOfMeasurement)
                    axiosPatient.symptoms[index].generalizedPain = isChecked;
                } else {
                    axiosPatient.symptoms.push(object);
                }
                break;
            case "flu":
                object.fluLikeSymptoms = isChecked;
                if (alreadyRegisteredBefore(object, pointOfMeasurement)) {
                    let index = axiosPatient.symptoms.findIndex((object: { pointOfMeasurement: string; }) => object.pointOfMeasurement === pointOfMeasurement)
                    axiosPatient.symptoms[index].fluLikeSymptoms = isChecked;
                } else {
                    axiosPatient.symptoms.push(object);
                }
                break;
            case "fever":
                object.fever = isChecked;
                if (alreadyRegisteredBefore(object, pointOfMeasurement)) {
                    let index = axiosPatient.symptoms.findIndex((object: { pointOfMeasurement: string; }) => object.pointOfMeasurement === pointOfMeasurement)
                    axiosPatient.symptoms[index].fever = isChecked;
                } else {
                    axiosPatient.symptoms.push(object);
                }
                break;
            case "fatigue":
                object.fatigue = isChecked;
                if (alreadyRegisteredBefore(object, pointOfMeasurement)) {
                    let index = axiosPatient.symptoms.findIndex((object: { pointOfMeasurement: string; }) => object.pointOfMeasurement === pointOfMeasurement)
                    axiosPatient.symptoms[index].fatigue = isChecked;
                } else {
                    axiosPatient.symptoms.push(object);
                }
                break;
            case "jointPain":
                object.jointPain = isChecked;
                if (alreadyRegisteredBefore(object, pointOfMeasurement)) {
                    let index = axiosPatient.symptoms.findIndex((object: { pointOfMeasurement: string; }) => object.pointOfMeasurement === pointOfMeasurement)
                    axiosPatient.symptoms[index].jointPain = isChecked;
                } else {
                    axiosPatient.symptoms.push(object);
                }
                break;
        }
    }


    if (id.includes(t('timings.beforeTreatment'))) {
        const symptomsValueToCheck = id.replace(t('timings.beforeTreatment'), '');
        switch (symptomsValueToCheck) {
            case " weightloss":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("beforeTreatment", "weightloss");
                break;
            case " muscleache":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("beforeTreatment", "muscleache");
                break;
            case " generalpain":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("beforeTreatment", "generalpain");
                break;
            case " flu":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("beforeTreatment", "flu");
                break;
            case " fever":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("beforeTreatment", "fever");
                break;
            case " fatigue":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("beforeTreatment", "fatigue");
                break;
            case " jointPain":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("beforeTreatment", "jointPain");
                break;
        }
    }

    else if (id.includes(t('timings.afterLastTreatment'))) {
        const symptomsValueToCheck = id.replace(t('timings.afterLastTreatment'), '');

        switch (symptomsValueToCheck) {
            case " weightloss":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterLastTreatment", "weightloss");
                break;
            case " muscleache":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterLastTreatment", "muscleache");
                break;
            case " generalpain":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterLastTreatment", "generalpain");
                break;
            case " flu":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterLastTreatment", "flu");
                break;
            case " fever":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterLastTreatment", "fever");
                break;
            case " fatigue":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterLastTreatment", "fatigue");
                break;
            case " jointPain":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterLastTreatment", "jointPain");
                break;
        }
    }

    else if (id.includes(t('timings.afterOneMonth'))) {
        const symptomsValueToCheck = id.replace(t('timings.afterOneMonth'), '');

        switch (symptomsValueToCheck) {
            case " weightloss":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterOneMonth", "weightloss");
                break;
            case " muscleache":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterOneMonth", "muscleache");
                break;
            case " generalpain":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterOneMonth", "generalpain");
                break;
            case " flu":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterOneMonth", "flu");
                break;
            case " fever":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterOneMonth", "fever");
                break;
            case " fatigue":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterOneMonth", "fatigue");
                break;
            case " jointPain":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterOneMonth", "jointPain");
                break;
        }
    }

    else if (id.includes(t('timings.afterThreeMonths'))) {
        const symptomsValueToCheck = id.replace(t('timings.afterThreeMonths'), '');

        switch (symptomsValueToCheck) {
            case " weightloss":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterThreeMonths", "weightloss");
                break;
            case " muscleache":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterThreeMonths", "muscleache");
                break;
            case " generalpain":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterThreeMonths", "generalpain");
                break;
            case " flu":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterThreeMonths", "flu");
                break;
            case " fever":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterThreeMonths", "fever");
                break;
            case " fatigue":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterThreeMonths", "fatigue");
                break;
            case " jointPain":
                checkIfAlreadyRegisteredAndReplaceOrAddNew("afterThreeMonths", "jointPain");
                break;
        }
    }
}

export {saveSymptomsValues};