import React, {useContext, useEffect, useState} from "react";
import DropdownSelection from "../../molecules/DropdownSelection/DropdownSelection";
import InputText from "../../atoms/InputText/InputText";
import './singleInformation.css';
import '../../../App.css';
import {useTranslation} from "react-i18next";
import PreviousTherapies from "./PreviousTherapies/PreviousTherapies";
import GlobalContext from "../../context";
import Weeks from "./WeekFields/Weeks/Weeks";
import AutoAntibodies from "./AutoAntibodyFields/AutoAntibodies/AutoAntibodies";

export default function SingleInformation() {
    const {t} = useTranslation();
    const {axiosPatient} = useContext(GlobalContext);
    const [placeholderTypeOfExtracorporealTherapy, setPlaceholderTypeOfExtracorporealTherapy] = useState('');


    const optionsExtracorporalTherapy: string[] = [
        t("singleInformation.optionsExtracorporalTherapy.iaRegenerative"),
        t("singleInformation.optionsExtracorporalTherapy.iaTryptophan"),
        "DFPP",
        "HELP",
        t("singleInformation.optionsExtracorporalTherapy.plasmaExchange"),
    ];

    useEffect(() => {
        //TODO Einkürzen?
        if (axiosPatient.typeOfExtracorporealTherapy) {
            if (axiosPatient.typeOfExtracorporealTherapy === t("singleInformation.optionsExtracorporalTherapy.plasmaExchange")) {
                setPlaceholderTypeOfExtracorporealTherapy(t("singleInformation.optionsExtracorporalTherapy.plasmaExchange"));
            } else {
                setPlaceholderTypeOfExtracorporealTherapy(axiosPatient.typeOfExtracorporealTherapy)
            }
        }
    }, [axiosPatient.typeOfExtracorporealTherapy, t])

    return (
        <div className="singleInformation-inputMask-container">
            <header className="inputMask-main-header">{t('singleInformation.title')}</header>

            <header className="inputMask-subheading">{t('singleInformation.aak')}</header>
            <AutoAntibodies/>
            <InputText
                id={"otherak"}
                label={t('singleInformation.otheraak')}
                placeholder={axiosPatient.otherAak}
                type={'text'}
                variant={'outlined'}/>
            <header className="inputMask-subheading">{t('singleInformation.previousTherapies')}</header>
            <PreviousTherapies label={t("singleInformation.previousTherapies")} id={"previousTherapy"}/>
            <header className="inputMask-subheading">{t('singleInformation.extracorporalTherapy')}</header>
            <DropdownSelection
                options={optionsExtracorporalTherapy}
                label={t("singleInformation.optionsExtracorporalTherapy.title")}
                placeholder={placeholderTypeOfExtracorporealTherapy}
                id={"extracorporalTherapy"}/>
            <Weeks/>
            <div className="inputText-general">
                <InputText
                    id={"plasmaVolume"}
                    label={t('singleInformation.averagePlasmaVolume')}
                    placeholder={axiosPatient.averageTreatedPlasmaVolume}
                    type={"number"}
                    variant={"outlined"}/>
            </div>

        </div>
    );
}

