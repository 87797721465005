import React, {useContext} from "react";
import InputText from "../../../atoms/InputText/InputText";
import "../../../../App.css";
import {useTranslation} from "react-i18next";
import GlobalContext from "../../../context";

export default function Genotype() {
    const {t} = useTranslation();
    const {axiosPatient} = useContext(GlobalContext);

    return (
        <div className="inputText-general">
            <InputText
                id={"genotype"}
                label={t("mui.inputTextLabel.genotype")}
                type={"text"}
                placeholder={axiosPatient.genotype}
                variant={"outlined"}/>
        </div>
    );
}

