import React, {useContext, useEffect, useState} from "react";
import DropdownSelection from "../../../molecules/DropdownSelection/DropdownSelection";
import {useTranslation} from "react-i18next";
import GlobalContext from "../../../context";

export default function Severity() {
    const {t} = useTranslation();
    const {axiosPatient} = useContext(GlobalContext);
    const [placeholder, setPlaceholder] = useState(axiosPatient.severity?.toLocaleString());

    const optionsSeverity: string[] = [
        t("mui.dropdownSelectionLabel.optionsSeverity.ambulant"),
        t("mui.dropdownSelectionLabel.optionsSeverity.stationary"),
        t("mui.dropdownSelectionLabel.optionsSeverity.intensiveCareUnit"),
    ];

    useEffect(()=>{
        if (axiosPatient.severity === "ambulant") {
            setPlaceholder(t("mui.dropdownSelectionLabel.optionsSeverity.ambulant"));
        } else if (axiosPatient.severity === "stationary") {
            setPlaceholder(t("mui.dropdownSelectionLabel.optionsSeverity.stationary"))
        } else if (axiosPatient.severity === "intensiveCareUnit") {
            setPlaceholder(t("mui.dropdownSelectionLabel.optionsSeverity.intensiveCareUnit"))
        }
    },[t])

    return (
        <DropdownSelection
            options={optionsSeverity}
            placeholder={placeholder}
            label={t("mui.dropdownSelectionLabel.severity")}
            id={"severity"}
        />
    );
}

