import React from "react";
import './privacyPolicy.css';

export default function PrivacyPolicy() {

    return (
        <div className="privacyPolicy-container">
            <div className="privacyPolicy">
                <h1 className="privacyPolicy-header">Datenschutzerklärung</h1>
                <div className="privacyPolicy-text-padding">
                    Die Nutzung unserer Seite ist ohne eine Angabe von personenbezogenen Daten möglich. Für die Nutzung
                    einzelner Services unserer Seite können sich hierfür abweichende Regelungen ergeben, die in diesem
                    Falle nachstehend gesondert erläutert werden. Ihre personenbezogenen Daten (z.B. Name, Anschrift,
                    E-Mail, Telefonnummer, u.ä.) werden von uns nur gemäß den Bestimmungen des deutschen
                    Datenschutzrechts verarbeitet.
                </div>
                <div className="privacyPolicy-text-padding"> Daten sind dann personenbezogen, wenn sie eindeutig einer
                    bestimmten natürlichen Person zugeordnet werden können. Die rechtlichen Grundlagen des Datenschutzes
                    finden Sie im Bundesdatenschutzgesetz (BDSG) und dem Telemediengesetz (TMG). Nachstehende Regelungen
                    informieren Sie insoweit über die Art, den Umfang und Zweck der Erhebung, die Nutzung und die
                    Verarbeitung von personenbezogenen Daten durch den Anbieter:
                </div>

                <div className="company">
                    <span>BioArtProducts GmbH (BAP)</span>
                    <span>Kröpeliner Straße 54</span>
                    <span>18055 Rostock</span>
                    <span>0381/337 386 03</span>
                    <span>info@bioartproducts.de</span>
                </div>

                <h4>Cookies</h4>
                <div className="privacyPolicy-text-padding">
                    Wir verwenden auf unserer Seite sog. Cookies zum Wiedererkennen mehrfacher Nutzung unseres Angebots,
                    durch denselben Nutzer/Internetanschlussinhaber. Cookies sind kleine Textdateien, die Ihr Internet-
                    Browser auf Ihrem Rechner ablegt und speichert. Sie dienen dazu, unseren Internetauftritt und unsere
                    Angebote zu optimieren. Es handelt sich dabei zumeist um sog. "Session-Cookies", die nach dem Ende
                    Ihres Besuches wieder gelöscht werden.
                </div>
                <div className="privacyPolicy-text-padding">
                    Teilweise geben diese Cookies jedoch Informationen ab, um Sie automatisch wieder zu erkennen. Diese
                    Wiedererkennung erfolgt aufgrund der in den Cookies gespeicherten IP-Adresse.
                </div>
                <div className="privacyPolicy-text-padding">
                    Die so erlangten Informationen dienen dazu, unsere Angebote zu optimieren und Ihnen einen leichteren
                    Zugang auf unsere Seite zu ermöglichen. Sie können die Installation der Cookies durch eine
                    entsprechende Einstellung Ihres Browsers verhindern; wir weisen Sie jedoch darauf hin, dass Sie in
                    diesem Fall gegebenenfalls nicht sämtliche Funktionen unserer Website vollumfänglich nutzen können.
                </div>
                <h5>Serverdaten</h5>
                <div className="privacyPolicy-text-padding">
                    Aus technischen Gründen werden u.a. folgende Daten, die Ihr Internet-Browser an uns bzw. an unseren
                    Webspace-Provider übermittelt, erfasst (sogenannte Serverlogfiles):
                </div>
                <div className="serverlogfiles">
                        <li>Browsertyp und -version</li>
                        <li>verwendetes Betriebssystem</li>
                        <li>Webseite, von der aus Sie uns besuchen (Referrer URL)</li>
                        <li>Webseite, die Sie besuchen</li>
                        <li>Datum und Uhrzeit Ihres Zugriffs</li>
                        <li>Ihre Internet Protokoll (IP)-Adresse.</li>
                </div>
                <div className="privacyPolicy-text-padding">
                    Diese anonymen Daten werden getrennt von Ihren eventuell angegebenen personenbezogenen Daten
                    gespeichert und lassen so keine Rückschlüsse auf eine bestimmte Person zu. Sie werden zu
                    statistischen Zwecken ausgewertet, um unseren Internetauftritt und unsere Angebote optimieren zu
                    können.
                </div>
                <h5>Kontaktmöglichkeit</h5>
                <div className="privacyPolicy-text-padding">
                    Wir bieten Ihnen auf unserer Seite die Möglichkeit, mit uns per E-Mail und/oder über ein
                    Kontaktformular in Verbindung zu treten. In diesem Fall werden die vom Nutzer gemachten Angaben zum
                    Zwecke der Bearbeitung seiner Kontaktaufnahme gespeichert. Eine Weitergabe an Dritte erfolgt nicht.
                    Ein Abgleich der so erhobenen Daten mit Daten, die möglicherweise durch andere Komponenten unserer
                    Seite erhoben werden, erfolgt ebenfalls nicht.
                </div>

                <div className="privacyPolicy-text-padding">
                    Muster-Datenschutzerklärung der Anwaltskanzlei Weiß & Partner
                </div>
            </div>

        </div>
    );
}

