import React, {useContext, useEffect, useState} from "react";
import DropdownSelection from "../../../../molecules/DropdownSelection/DropdownSelection";
import GlobalContext from "../../../../context";

type Props = {
    label: string,
    id: string
}

export default function Week(props: Props) {
    const {axiosPatient} = useContext(GlobalContext);
    const [placeholder, setPlaceholder] = useState("");

    const numberOfWeeklyTreatments: string[] = [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
    ];

    useEffect(() => {
        let numberOfTreatments;
        let weekNumber;
        if (axiosPatient.weeks !== null) {
            if (props.id === "numberOfTreatmentsWeek1") {
                weekNumber = axiosPatient.weeks.find(week => week.number === 1);
                if (weekNumber) {
                    numberOfTreatments = weekNumber.numberOfTreatments;
                    setPlaceholder(numberOfTreatments.toLocaleString());
                }
            } else if (props.id === "numberOfTreatmentsWeek2") {
                weekNumber = axiosPatient.weeks.find(week => week.number === 2);
                if (weekNumber) {
                    numberOfTreatments = weekNumber.numberOfTreatments;
                    setPlaceholder(numberOfTreatments.toLocaleString());
                }
            } else if (props.id === "numberOfTreatmentsWeek3") {
                weekNumber = axiosPatient.weeks.find(week => week.number === 3);
                if (weekNumber) {
                    numberOfTreatments = weekNumber.numberOfTreatments;
                    setPlaceholder(numberOfTreatments.toLocaleString());
                }
            } else if (props.id === "numberOfTreatmentsWeek4") {
                weekNumber = axiosPatient.weeks.find(week => week.number === 4);
                if (weekNumber) {
                    numberOfTreatments = weekNumber.numberOfTreatments;
                    setPlaceholder(numberOfTreatments.toLocaleString());
                }
            }
        }
    },[])

    return (
        <>
            <DropdownSelection
                options={numberOfWeeklyTreatments}
                placeholder={placeholder}
                label={props.label}
                id={props.id}
                typeOfMeasurement={'numberOfWeeklyTreatments'}/>
        </>

    );
}

