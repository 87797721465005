import React from "react";
import '../clinicalEvaluation.css';
import CheckboxLabeled from "../../../atoms/CheckboxLabeled/CheckboxLabeled";

type Props = {
    label: string,
    id: string,
}

export default function SymptomsAfterCovidSingle(props: Props) {

    return (
        <div className="symptomsAfterCovid-value">
            <CheckboxLabeled id={props.id}
                             label={props.label}
                             typeOfMeasurement={"symptomsAfterCovid"}
            />
        </div>
    );
}