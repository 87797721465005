import React from "react";
import './legalNotice.css';

export default function LegalNotice() {

    return (
        <div className="legalNotice-container">
            <div className="legalNotice">
                <h1 className="legalNotice-header">Impressum</h1>
                <h4 className="legalNotice-subheader">Träger des Registers:</h4>
                <div className="company">
                    <span>European Group - International Society for Apheresis</span>
                    <span>Office:</span>
                    <span>Schliemannstr. 2</span>
                    <span>18059 Rostock, Germany</span>
                    <span>Email: info@e-isfa.eu</span>
                </div>

                <div className="company">
                    <h4 className="legalNotice-subheader">Technischer Dienstleister:</h4>
                    <span>BioArtProducts GmbH (BAP)</span>
                    <span>Kröpeliner Str.54</span>
                    <span>18055 Rostock</span>
                    <span>t.zimmermann@bioartproducts.de</span>
                </div>
            </div>
        </div>
    );
}

