import React, {useContext, useEffect, useState} from "react";
import InputLabel from "@mui/material/InputLabel";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import "../../../App.css";
import {dropdownSeverity, dropdownAlignInput} from "./dropdownSelectionStyles";
import GlobalContext from "../../context";
import {saveAntibodies} from "./saveAntibodies";
import {saveWeeks} from "./saveWeeks";
import {useTranslation} from "react-i18next";

type Props = {
    options: string[],
    label: string,
    id: string,
    placeholder?: string,
    typeOfMeasurement?: string,
}

export default function DropdownSelection(props: Props) {
    const [value, setValue] = useState(props.placeholder?.toString());
    const {axiosPatient} = useContext(GlobalContext);
    const {t} = useTranslation();

    useEffect(() => {
        setValue(props.placeholder);
    }, [props.placeholder])

    const handleChangeValue = (event: SelectChangeEvent) => {
        setValue(event.target.value as string);

        if (props.id === "sex") {
            if (event.target.value === "weiblich" || event.target.value === "männlich") {
                switch (event.target.value) {
                    case 'weiblich':
                        axiosPatient.sex = 'female';
                        break;
                    case 'männlich':
                        axiosPatient.sex = 'male';
                        break;
                }
            } else {
                axiosPatient.sex = event.target.value;
            }
        }

        // Todo EINKÜRZEN!
        if (props.id === "severity") {
            if (event.target.value === "Ambulant behandelt" || event.target.value === "Stationär behandelt" || event.target.value === "Therapie auf Intensivstation") {
                switch (event.target.value) {
                    case 'Ambulant behandelt':
                        axiosPatient.severity = 'ambulant';
                        break;
                    case 'Stationär behandelt':
                        axiosPatient.severity = 'stationary';
                        break;
                    case 'Therapie auf Intensivstation':
                        axiosPatient.severity = 'intensiveCareUnit';
                        break;
                }
            } else if (event.target.value === "Outpatient Treatment" || event.target.value === "Inpatient Treatment" || event.target.value === "Therapy in the Intensive Care Unit") {
                switch (event.target.value) {
                    case 'Outpatient Treatment':
                        axiosPatient.severity = 'ambulant';
                        break;
                    case 'Inpatient Treatment':
                        axiosPatient.severity = 'stationary';
                        break;
                    case 'Therapy in the Intensive Care Unit':
                        axiosPatient.severity = 'intensiveCareUnit';
                        break;
                }
            }
        }

        if (props.id === "vaccination") {
            axiosPatient.vaccinated = event.target.value === "Ja" || event.target.value === "Yes";
        }
        if (props.id === "repeatedIllness") {
            axiosPatient.recurrentDisease = event.target.value === "Ja" || event.target.value === "Yes";
        }
        if (props.id === "extracorporalTherapy") {
            axiosPatient.typeOfExtracorporealTherapy = event.target.value;
        }
        // Antibodies
        if (props.typeOfMeasurement === "autoantibody") {

            let eventToSave;
            if (event.target.value === "Negativ" || event.target.value === "Grenzwertig" || event.target.value === "Positiv" || event.target.value === "Nicht bestimmt") {
                switch (event.target.value) {
                    case 'Negativ':
                        eventToSave = 'negative';
                        break;
                    case 'Grenzwertig':
                        eventToSave = 'borderline';
                        break;
                    case 'Positiv':
                        eventToSave = 'positive';
                        break;
                    case 'Nicht bestimmt':
                        eventToSave = 'undefined';
                        break;
                }
                saveAntibodies(props.id, eventToSave, axiosPatient);
            } else if (event.target.value === "Negative" || event.target.value === "Borderline" || event.target.value === "Positive" || event.target.value === "Undefined") {
                    switch (event.target.value) {
                        case 'Negative':
                            eventToSave = 'negative';
                            break;
                        case 'Borderline':
                            eventToSave = 'borderline';
                            break;
                        case 'Positive':
                            eventToSave = 'positive';
                            break;
                        case 'Undefined':
                            eventToSave = 'undefined';
                            break;
                    }
                    saveAntibodies(props.id, eventToSave, axiosPatient);
            }
        }
        if (props.id === "extracorporalTherapy") {
            axiosPatient.typeOfExtracorporealTherapy = event.target.value;
        }
        if (props.typeOfMeasurement === "numberOfWeeklyTreatments") {
            saveWeeks(props.id, event, axiosPatient);
        }
    };


    return (
        <FormControl className="formfield" sx={dropdownSeverity}>
            <InputLabel id={props.id}>{props.label}</InputLabel>
            <Select
                sx={dropdownAlignInput}
                labelId={props.id}
                id={props.id}
                value={value}
                defaultValue={""}
                label={props.label}
                onChange={handleChangeValue}
            >

                {props.options.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

