import React, {useContext, useEffect, useState} from "react";
import DropdownSelection from "../../../molecules/DropdownSelection/DropdownSelection";
import {useTranslation} from "react-i18next";
import GlobalContext from "../../../context";

export default function Sex() {
    const {t} = useTranslation();
    const {axiosPatient} = useContext(GlobalContext);
    const [placeholder, setPlaceholder] = useState("");

    const optionsSex: string[] = [
        t("mui.dropdownSelectionLabel.optionsSex.female"),
        t("mui.dropdownSelectionLabel.optionsSex.male"),
    ];

    useEffect(()=>{
        if (axiosPatient.sex === 'male') {
            setPlaceholder(t("mui.dropdownSelectionLabel.optionsSex.male"));
        } else if (axiosPatient.sex === 'female') {
            setPlaceholder(t("mui.dropdownSelectionLabel.optionsSex.female"));
        }
    },[t, axiosPatient.sex])

    return (
        <DropdownSelection
            options={optionsSex}
            placeholder={placeholder}
            label={t("mui.dropdownSelectionLabel.sex")}
            id={"sex"}
        />
    );
}

