import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {Fab} from "@mui/material";
import InfectionDate from "./Fields/InfectionDate";
import Sex from "./Fields/Sex";
import BirthYear from "./Fields/BirthYear";
import Genotype from "./Fields/Genotype";
import Severity from "./Fields/Severity";
import Complications from "./Fields/Complications";
import Vaccination from "./Fields/Vaccination";
import SingleInformation from "../SingleInformation/SingleInformation";
import ClinicalEvaluation from "../ClinicalEvaluation/ClinicalEvaluation";
import RepeatedCovid from "./Fields/RepeatedCovid";
import "./basicData.css";
import {floatingButtonPatientList, floatingButtonSave} from './basicDataStyles';
import GlobalContext from "../../context";
import {useTranslation} from "react-i18next";
import Height from "./Fields/Height";
import Weight from "./Fields/Weight";
import axios from "axios";
import moment from "moment";

export default function BasicData() {
    const {axiosPatient} = useContext(GlobalContext);
    const {currentCenterId} = useContext(GlobalContext);
    const navigate = useNavigate();
    const {t} = useTranslation();

    const goBackToPatientList = () => {
        navigate('/patientlist');
    }

    const savePatient = () => {
        //navigate('/patientlist'); http://localhost:8080/v1/patient
        console.log("saved patient: ", axiosPatient);
        //axios.put('http://localhost:8080/v1/patient/update', axiosPatient, {withCredentials: true}).then(user => {
            axios.put('/v1/patient/update', axiosPatient, {withCredentials: true}).then(user => {
        });
        navigate('/patientlist');
    }

    return (
        <>
            <div className="basicData-header-text">
                <div className="basicData-center-id">{t('patientlist.centerid')} {currentCenterId}</div>
                <header className="inputMask-main-header">{t('basicdata.title')}</header>
                <div className="basicData">
                    <Fab sx={floatingButtonPatientList} variant="extended" onClick={goBackToPatientList}>
                        {t('floatingButtons.toPatientlist')}
                    </Fab>
                    <Fab sx={floatingButtonSave} variant="extended" onClick={savePatient}>
                        {t('floatingButtons.save')}
                    </Fab>

                    <div className="basicData-id-date">
                        <div>{t('basicdata.patientid')} {axiosPatient.identification}</div>
                        <div>{t('basicdata.entrydate')} {moment(axiosPatient.entryDate)?.format("DD.MM.YYYY")}</div>
                    </div>
                    <BirthYear/>
                    <Sex/>
                    <Height/>
                    <Weight/>
                    <InfectionDate/>
                    <Genotype/>
                    <Severity/>
                    <Complications/>
                    <Vaccination/>
                    <RepeatedCovid/>
                    {/*Todo: Rename BasicData*/}
                    <SingleInformation/>
                    <ClinicalEvaluation/>
                </div>

            </div>
        </>
    );
}

