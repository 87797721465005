import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import GlobalContext from "../../../context";
import './center.css';
import {Fab} from "@mui/material";
import {floatingButtonPatientList, floatingButtonSave} from "../../BasicData/basicDataStyles";
import Id from "./Fields/Id";
import Name from "./Fields/Name";
import Users from "./Fields/Users";

export default function Center() {
    const {selectedCenterIdAdmin} = useContext(GlobalContext);
    const navigate = useNavigate();

    const goBackToCenterList = () => {
        navigate('/admin');
    }

    return (
        <>
            <div className="center-header-text">
                <header className="inputMask-main-header">Übersicht Zentrum ID {selectedCenterIdAdmin}</header>
                <div className="center">
                    <Fab sx={floatingButtonPatientList} variant="extended" onClick={goBackToCenterList}>
                       Zur Zentrumsübersicht
                    </Fab>
                    <Fab sx={floatingButtonSave} variant="extended" onClick={goBackToCenterList}>
                        Speichern
                    </Fab>

                    <Id/>
                    <Name/>
                    <Users/>
                </div>

            </div>
        </>
    );
}

