import React, {useContext} from "react";
import {useNavigate} from 'react-router-dom';
import Button from "@mui/material/Button";
import logo from '../../../assets/isfa-eu.svg';
import HeaderHyperlinks from "../../molecules/HeaderHyperlinks/HeaderHyperlinks";
import Login from "../../pages/Login/Login";
import GlobalContext from "../../context";
import {loginButton} from "./headerStyles";
import "./header.css";
import axios from "axios";
import {useTranslation} from "react-i18next";
import IconButton from '@material-ui/core/IconButton';

// @ts-ignore
import {IconFlagDE, IconFlagUK} from 'material-ui-flags';

export default function Header() {
    const {buttonLoginLogout, setButtonLoginLogout} = useContext(GlobalContext);
    const {setShowLoginDialog} = useContext(GlobalContext);
    const navigate = useNavigate();
    const {isAuthenticated, setIsAuthenticated} = useContext(GlobalContext);
    const {setCurrentUser} = useContext(GlobalContext);
    const {t} = useTranslation();

    const handleLoginLogout = () => {
        if (isAuthenticated) {
            signOut();
        } else if (!isAuthenticated) {
            setShowLoginDialog(true);
        }
    }

    const { i18n } = useTranslation();

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    const signOut = () => {
        // axios.post('http://localhost:8080/v1/user/signout', {}, {withCredentials: true})
        axios.post('/v1/user/signout', {}, {withCredentials: true})
            .then(result => {
                setIsAuthenticated(false);
                setCurrentUser({});
                setButtonLoginLogout("Login");
                navigate('/');
            })
            .catch((e) => {
                console.log("logout failed: ", e)
            })
    }

    const backToMain = () => {
        navigate('/')
    }

    return (
        <div className="header-background">
            <img src={logo} alt={'replace with e-isfa logo'} onClick={() => backToMain()} style={{cursor: 'pointer'}}/>
            <div className="header-title">
                <div> {t('title')}</div>
                <IconButton onClick={() => changeLanguage('en')}><IconFlagUK /></IconButton>
                <IconButton onClick={() => changeLanguage('de')}><IconFlagDE /></IconButton>
            </div>

            <div className="header-hyperlinks-button">
                <HeaderHyperlinks/>
                <div className="header-button">
                    <Button variant="contained" sx={loginButton}
                            onClick={handleLoginLogout}>{buttonLoginLogout}
                    </Button>
                </div>
            </div>
            <Login/>

        </div>
    );
}

