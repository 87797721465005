import React, {useContext} from "react";
import InputText from "../../../atoms/InputText/InputText";
import "../../../../App.css";
import {useTranslation} from "react-i18next";
import GlobalContext from "../../../context";

export default function Weight() {
    const {t} = useTranslation();
    const {axiosPatient} = useContext(GlobalContext);

    return (
        <div className="inputText-general">
            <InputText
                id={"weight"}
                label={t("mui.inputTextLabel.weight")}
                placeholder={axiosPatient.weight?.toLocaleString()}
                type={"number"}
                variant={"outlined"}
            />
        </div>
    );
}

