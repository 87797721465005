import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import GlobalContext from "../../../context";
import MultiSelection from "../../../molecules/MultiSelection/MultiSelection";

type Props = {
    label: string,
    id: string
}

export default function PreviousTherapies(props: Props) {
    const {t} = useTranslation();
    const {axiosPatient} = useContext(GlobalContext);
    const [placeholder, setPlaceholder] = useState("");

    const optionsPreviousTherapies: string[] = [
        t("singleInformation.optionsPreviousTherapies.cardiology"),
        t("singleInformation.optionsPreviousTherapies.neurology"),
        t("singleInformation.optionsPreviousTherapies.psychology"),
        t("singleInformation.optionsPreviousTherapies.hematology"),
        t("singleInformation.optionsPreviousTherapies.pneumology"),
        t("singleInformation.optionsPreviousTherapies.other"),
    ];

    useEffect(() => {

        let listedPreviousTherapies = axiosPatient.previousTherapy;
        let listedPreviousTherapiesToShow = "";

        for (const [key, val] of Object.entries(listedPreviousTherapies)) {
            if (val === true) {
                let translatedKey = '';
                if (key === 'cardiology') {
                    translatedKey =  t("singleInformation.optionsPreviousTherapies.cardiology");
                    listedPreviousTherapiesToShow += "" + translatedKey + " ";
                } else if (key === 'hematology') {
                    translatedKey = t("singleInformation.optionsPreviousTherapies.hematology");
                    listedPreviousTherapiesToShow += "" + translatedKey + " ";
                } else if (key === 'neurology') {
                    translatedKey = t("singleInformation.optionsPreviousTherapies.neurology");
                    listedPreviousTherapiesToShow += "" + translatedKey + " ";
                } else if (key === 'psychology') {
                    translatedKey =  t("singleInformation.optionsPreviousTherapies.psychology");
                    listedPreviousTherapiesToShow += "" + translatedKey + " ";
                } else if (key === 'pneumology') {
                    translatedKey =  t("singleInformation.optionsPreviousTherapies.pneumology");
                    listedPreviousTherapiesToShow += "" + translatedKey + " ";
                }else if (key === 'otherTherapy') {
                    translatedKey = t("singleInformation.optionsPreviousTherapies.other");
                    listedPreviousTherapiesToShow += "" + translatedKey + " ";
                } else {
                    listedPreviousTherapiesToShow += "" + key + " ";
                }
                setPlaceholder(listedPreviousTherapiesToShow);
            }
        }
    },[t])

    return (
        <MultiSelection
            options={optionsPreviousTherapies}
            label={t("singleInformation.previousTherapies")}
            placeholder={placeholder}
            id={"previousTherapy"}
            labelFreeText={t("singleInformation.otherTherapies")}
            idFreeText={"other previous therapy"}
            typeOfMeasurement={'previousTherapies'}
        />
    );
}

