import React, {useContext} from "react";
import InputText from "../../../atoms/InputText/InputText";
import "../../../../App.css";
import {useTranslation} from "react-i18next";
import GlobalContext from "../../../context";

export default function BirthYear() {
    const {axiosPatient} = useContext(GlobalContext);
    const {t} = useTranslation();
    return (
        <div className="inputText-general">
            <InputText
                id={"birthyear"}
                label={t("mui.inputTextLabel.birthyear")}
                placeholder={axiosPatient.yearOfBirth}
                type={"text"}
                variant={"outlined"}
            />
        </div>
    );
}

