import React from "react";
import InputText from "../../../../atoms/InputText/InputText";

export default function Name() {

    return (
        <div className="inputText-general">
            <InputText
                id={"centerName"}
                label={"Zentrums-Name"}
                type={"text"}
                variant={"outlined"}
                placeholder={'test'}
            />
        </div>
    );
}

