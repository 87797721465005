import React, {useContext, useEffect, useState} from "react";
import DropdownSelection from "../../../molecules/DropdownSelection/DropdownSelection";
import {useTranslation} from "react-i18next";
import GlobalContext from "../../../context";

export default function RepeatedCovid() {
    const {t} = useTranslation();
    const {axiosPatient} = useContext(GlobalContext);
    const [placeholder, setPlaceholder] = useState("");

    const optionsRepeatedCovid: string[] = [
        t("mui.dropdownSelectionLabel.optionsRepeatedCovid.yes"),
        t("mui.dropdownSelectionLabel.optionsRepeatedCovid.no"),
    ];

    useEffect(()=>{
        if (axiosPatient.recurrentDisease === true) {
            setPlaceholder(t("mui.dropdownSelectionLabel.optionsRepeatedCovid.yes"));
        } else if (axiosPatient.recurrentDisease === false) {
            setPlaceholder( t("mui.dropdownSelectionLabel.optionsRepeatedCovid.no"));
        } else {
            setPlaceholder("");
        }
    },[t])

    return (
        <DropdownSelection
            options={optionsRepeatedCovid}
            placeholder={placeholder}
            label={t("mui.dropdownSelectionLabel.repeatedCovid")}
            id={"repeatedIllness"}
        />
    );
}

