import React, {useContext, useEffect, useState} from "react";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import deLocale from 'date-fns/locale/de';
import {datePickerTablet, datePickerDesktop} from "./calendarStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import enLocale from "date-fns/locale/en-GB";
import i18next from "i18next";
import GlobalContext, {defaultAxiosPatient} from "../../context";

export interface Props {
    id?: string,
    placeholder?: Date,
    label: string;
    inputFormat: string;
    labTime?: string;
    scoreTime?: string;
}

export default function CalendarInput(props: Props) {

    const [value, setValue] = useState<Date | null | undefined>(props.placeholder);
    const {axiosPatient} = useContext(GlobalContext);
    const {currentPatient} = useContext(GlobalContext);
    const matches = useMediaQuery('(max-width: 1265px)');
    const [style, setStyle] = useState(datePickerDesktop);
    const [locale, setLocale] = useState(deLocale);

    // Display already saved Dates in Patient Object
    useEffect(() => {
        if (props.id === "labValuesDate" && (axiosPatient.labValues !== null)) {
            if (props.labTime === 'beforeTreatment') {
                Object.keys(axiosPatient.labValues).forEach(function(key) {
                    // @ts-ignore
                    if (axiosPatient.labValues[key].pointOfMeasurement === 'beforeTreatment') {
                        // @ts-ignore
                        setValue(axiosPatient.labValues[key].measurementDate);
                    }
                })
            } else if (props.labTime === 'afterLastTreatment') {
                Object.keys(axiosPatient.labValues).forEach(function(key) {
                    // @ts-ignore
                    if (axiosPatient.labValues[key].pointOfMeasurement === 'afterLastTreatment') {
                        // @ts-ignore
                        setValue(axiosPatient.labValues[key].measurementDate);
                    }
                })
            } else if (props.labTime === 'afterOneMonth') {
                Object.keys(axiosPatient.labValues).forEach(function(key) {
                    // @ts-ignore
                    if (axiosPatient.labValues[key].pointOfMeasurement === 'afterOneMonth') {
                        // @ts-ignore
                        setValue(axiosPatient.labValues[key].measurementDate);
                    }
                })
            } else if (props.labTime === 'afterThreeMonths') {
                Object.keys(axiosPatient.labValues).forEach(function(key) {
                    // @ts-ignore
                    if (axiosPatient.labValues[key].pointOfMeasurement === 'afterThreeMonths') {
                        // @ts-ignore
                        setValue(axiosPatient.labValues[key].measurementDate);
                    }
                })
            }
        }
        if (props.id === "scoreValuesDate" && (axiosPatient.scores !== null)) {
            if (props.scoreTime === 'beforeTreatment') {
                Object.keys(axiosPatient.scores).forEach(function(key) {
                    // @ts-ignore
                    if (axiosPatient.scores[key].pointOfMeasurement === 'beforeTreatment') {
                        // @ts-ignore
                        setValue(axiosPatient.scores[key].measurementDate);
                    }
                })
            } else if (props.scoreTime === 'afterLastTreatment') {
                Object.keys(axiosPatient.scores).forEach(function(key) {
                    // @ts-ignore
                    if (axiosPatient.scores[key].pointOfMeasurement === 'afterLastTreatment') {
                        // @ts-ignore
                        setValue(axiosPatient.scores[key].measurementDate);
                    }
                })
            } else if (props.scoreTime === 'afterOneMonth') {
                Object.keys(axiosPatient.scores).forEach(function(key) {
                    // @ts-ignore
                    if (axiosPatient.scores[key].pointOfMeasurement === 'afterOneMonth') {
                        // @ts-ignore
                        setValue(axiosPatient.scores[key].measurementDate);
                    }
                })
            } else if (props.scoreTime === 'afterThreeMonths') {
                Object.keys(axiosPatient.scores).forEach(function(key) {
                    // @ts-ignore
                    if (axiosPatient.scores[key].pointOfMeasurement === 'afterThreeMonths') {
                        // @ts-ignore
                        setValue(axiosPatient.scores[key].measurementDate);
                    }
                })
            }
        }
    },[props.id])

    const handleChangeDate = (newValue: Date | null) => {
        setValue(newValue);
        if (props.id === "infectionDate") {
            if (newValue) {
                axiosPatient.covidInfection = newValue;
            }
        }
        if (props.id === "labValuesDate") {
            if (newValue) {
                if (props.labTime === 'beforeTreatment') {
                    currentPatient.labValueDateBefore = newValue;
                    Object.keys(axiosPatient.labValues).forEach(function(key) {
                        // @ts-ignore
                        if (axiosPatient.labValues[key].pointOfMeasurement === 'beforeTreatment') {
                            // @ts-ignore
                            axiosPatient.labValues[key].measurementDate = newValue;
                        }
                    })
                } else if (props.labTime === 'afterLastTreatment') {
                    currentPatient.labValueDateAfter = newValue;
                    Object.keys(axiosPatient.labValues).forEach(function(key) {
                        // @ts-ignore
                        if (axiosPatient.labValues[key].pointOfMeasurement === 'afterLastTreatment') {
                            // @ts-ignore
                            axiosPatient.labValues[key].measurementDate = newValue;
                        }
                    })
                } else if (props.labTime === 'afterOneMonth') {
                    currentPatient.labValueDateOneMonth = newValue;
                    Object.keys(axiosPatient.labValues).forEach(function(key) {
                        // @ts-ignore
                        if (axiosPatient.labValues[key].pointOfMeasurement === 'afterOneMonth') {
                            // @ts-ignore
                            axiosPatient.labValues[key].measurementDate = newValue;
                        }
                    })
                } else if (props.labTime === 'afterThreeMonths') {
                    currentPatient.labValueDateThreeMonths = newValue;
                    Object.keys(axiosPatient.labValues).forEach(function(key) {
                        // @ts-ignore
                        if (axiosPatient.labValues[key].pointOfMeasurement === 'afterThreeMonths') {
                            // @ts-ignore
                            axiosPatient.labValues[key].measurementDate = newValue;
                        }
                    })
                }
            }
        }

        if (props.id === "scoreValuesDate") {
            if (newValue) {
                if (props.scoreTime === 'beforeTreatment') {
                    currentPatient.scoreDateBefore = newValue;
                    Object.keys(axiosPatient.scores).forEach(function(key) {
                        // @ts-ignore
                        if (axiosPatient.scores[key].pointOfMeasurement === 'beforeTreatment') {
                            // @ts-ignore
                            axiosPatient.scores[key].measurementDate = newValue;
                        }
                    })
                } else if (props.scoreTime === 'afterLastTreatment') {
                    currentPatient.scoreDateAfter = newValue;
                    Object.keys(axiosPatient.scores).forEach(function(key) {
                        // @ts-ignore
                        if (axiosPatient.scores[key].pointOfMeasurement === 'afterLastTreatment') {
                            // @ts-ignore
                            axiosPatient.scores[key].measurementDate = newValue;
                        }
                    })
                } else if (props.scoreTime === 'afterOneMonth') {
                    currentPatient.scoreDateOneMonth = newValue;
                    Object.keys(axiosPatient.scores).forEach(function(key) {
                        // @ts-ignore
                        if (axiosPatient.scores[key].pointOfMeasurement === 'afterOneMonth') {
                            // @ts-ignore
                            axiosPatient.scores[key].measurementDate = newValue;
                        }
                    })
                } else if (props.scoreTime === 'afterThreeMonths') {
                    currentPatient.scoreDateThreeMonths = newValue;
                    Object.keys(axiosPatient.scores).forEach(function(key) {
                        // @ts-ignore
                        if (axiosPatient.scores[key].pointOfMeasurement === 'afterThreeMonths') {
                            // @ts-ignore
                            axiosPatient.scores[key].measurementDate = newValue;
                        }
                    })
                }
            }
        }
    };

    useEffect( () => {
        const handleResize = () => {
            console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
        }
        window.addEventListener('resize', handleResize);
        if (matches) {
            setStyle(datePickerTablet);
        } else {
            setStyle(datePickerDesktop);
        }
    }, [matches])

    useEffect(()=> {
        const currentLanguage = i18next.language || window.localStorage.i18nextLng;
        if (currentLanguage === "de") {
            setLocale(deLocale);
        } else {
            setLocale(enLocale);
        }

    // eslint-disable-next-line
    },[i18next.language])

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}
                              adapterLocale={locale}>
            <DesktopDatePicker
                label={props.label}
                inputFormat={props.inputFormat}
                value={value}
                onChange={handleChangeDate}
                renderInput={(params) => <TextField {...params} sx={style}/>}
            />
        </LocalizationProvider>
    );
}

