const checkIfAlreadyRegisteredAndReplace = (
    entryToCheck: string,
    array: [{ id: number, name: string, state: string, patientId: number }],
    objectToInsert: { id: number, name: string, state: string, patientId: number }) => {
    const alreadyRegistered = array.find((object) => {
        return object.name === entryToCheck;
    });

    if (alreadyRegistered) {
        const index = array.findIndex(object => {
            return object.name === entryToCheck;
        });
        objectToInsert.id = array[index].id;
        array[index] = objectToInsert;
    } else {
        array.push(objectToInsert);
    }
}

const setStateValueEng = (antibodyState: string) => {
    if (antibodyState === "Positiv") {
        antibodyState = 'positive'
    } else if (antibodyState === "Grenzwertig") {
        antibodyState = 'borderline'
    } else if (antibodyState === "Negativ") {
        antibodyState = 'negative'
    } else if (antibodyState === "Nicht bestimmt") {
        antibodyState = 'undefined'
    } else {
        return;
    }
    return antibodyState;
}

function saveAntibodies(id: string, event: any, axiosPatient: any) {

    if (id === "beta1akk") {
        const antibody = {
            id: 0,
            name: 'beta1akk',
            //state: event.target.value,
            state: event,
            patientId: axiosPatient.id
        };

        //antibody.state = setStateValueEng(antibody.state);
        checkIfAlreadyRegisteredAndReplace("beta1akk", axiosPatient.antibodies, antibody);
    }

    if (id === "m2akk") {
        const antibody = {
            id: 0,
            name: 'm2akk',
            state: event,
            patientId: axiosPatient.id
        };

        //antibody.state = setStateValueEng(antibody.state);
        checkIfAlreadyRegisteredAndReplace("m2akk", axiosPatient.antibodies, antibody);
    }
    if (id === "alpha1akk") {
        const antibody = {
            id: 0,
            name: 'alpha1akk',
            state: event,
            patientId: axiosPatient.id
        };

        //antibody.state = setStateValueEng(antibody.state);
        checkIfAlreadyRegisteredAndReplace("alpha1akk", axiosPatient.antibodies, antibody);
    }
    if (id === "at1ace2akk") {
        const antibody = {
            id: 0,
            name: 'at1ace2akk',
            state: event,
            patientId: axiosPatient.id
        };

        //antibody.state = setStateValueEng(antibody.state);
        checkIfAlreadyRegisteredAndReplace("at1ace2akk", axiosPatient.antibodies, antibody);
    }
    if (id === "etaak") {
        const antibody = {
            id: 0,
            name: 'etaak',
            state: event,
            patientId: axiosPatient.id
        };

        //antibody.state = setStateValueEng(antibody.state);
        checkIfAlreadyRegisteredAndReplace("etaak", axiosPatient.antibodies, antibody);
    }
    if (id === "beta2akk") {
        const antibody = {
            id: 0,
            name: 'beta2akk',
            state: event,
            patientId: axiosPatient.id
        };

        //antibody.state = setStateValueEng(antibody.state);
        checkIfAlreadyRegisteredAndReplace("beta2akk", axiosPatient.antibodies, antibody);
    }
    if (id === "paraak") {
        const antibody = {
            id: 0,
            name: 'paraak',
            state: event,
            patientId: axiosPatient.id
        };

        //antibody.state = setStateValueEng(antibody.state);
        checkIfAlreadyRegisteredAndReplace("paraak", axiosPatient.antibodies, antibody);
    }
}

export {saveAntibodies};