const newCenterButton = {
    width: "250px",
    marginTop: "5%",
    alignSelf: "flex-start",
    backgroundColor: "#2A57A5",
    "&:hover": {
        backgroundColor: "#0288d1",
    },
};

export { newCenterButton };