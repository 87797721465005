import React, {useContext} from 'react';
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import PatientList from "../../PatientList/PatientList";
import BasicData from "../../BasicData/BasicData";
import Landingpage from "../../Landingpage/Landingpage";
import GlobalContext from "../../../context";
import Admin from "../../Admin/Admin";
import Center from "../../Admin/Center/Center";
import User from "../../Admin/User/User";
import Contact from "../../Contact/Contact";
import PrivacyPolicy from "../../PrivacyPolicy/PrivacyPolicy";
import LegalNotice from "../../LegalNotice/LegalNotice";

function AllRoutes() {

    const {isAuthenticated} = useContext(GlobalContext);

    function RequireAuth({children}: { children: JSX.Element }) {
        let location = useLocation();

        if (isAuthenticated) {
            return children;
        } else {
            return <Navigate to="/" state={{from: location}} replace/>;
        }
    }

    return (
        <Routes>
            <Route path="/" element={<Landingpage/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/privacypolicy" element={<PrivacyPolicy/>}/>
            <Route path="/legalnotice" element={<LegalNotice/>}/>
            <Route path="/patientlist" element={
                <RequireAuth>
                    <PatientList/>
                </RequireAuth>
            }/>
            <Route path="/basicdata" element={
                <RequireAuth>
                    <BasicData/>
                </RequireAuth>
            }/>
            <Route path="/admin" element={
                <RequireAuth>
                    <Admin/>
                </RequireAuth>
            }/>
            <Route path="/center" element={
                <RequireAuth>
                    <Center/>
                </RequireAuth>
            }/>
            <Route path="/user" element={
                <RequireAuth>
                    <User/>
                </RequireAuth>
            }/>
        </Routes>
    );
}

export default AllRoutes;
