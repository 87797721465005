import React, {useState} from 'react';
import './App.css';
import GlobalContext, {defaultAxiosPatient, defaultCurrentPatient} from "./components/context";
import Header from "./components/organisms/Header/Header";
import {BrowserRouter} from "react-router-dom";
import AllRoutes from "./components/pages/Routes/AllRoutes/AllRoutes";

function App() {

    const [showLoginDialog, setShowLoginDialog] = useState(false);
    const [showConsentDialog, setShowConsentDialog] = useState(false);
    const [openNewCenter, setOpenNewCenter] = useState(false);
    const [openNewUser, setOpenNewUser] = useState(false);
    const [selectedCenterIdAdmin, setSelectedCenterIdAdmin] = useState(0);
    const [selectedUserId, setSelectedUserId] = useState(0);
    const [buttonLoginLogout, setButtonLoginLogout] = useState("Login");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword1, setNewPassword1] = useState("");
    const [newPassword2, setNewPassword2] = useState("");
    const [selectedPatientId, setSelectedPatientId] = useState(0);
    const [selectedPatientIdentification, setSelectedPatientIdentification] = useState(0);
    const [currentPatient, setCurrentPatient] = useState(defaultCurrentPatient);
    const [axiosPatient, setAxiosPatient] = useState(defaultAxiosPatient);
    const [currentCenterId, setCurrentCenterId] = useState(0);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [currentUser, setCurrentUser] = useState({});
    const [isChecked, setIsChecked] = useState(false);
    const [activeAdminTab, setActiveAdminTab] = useState(0);
    const [otherComplicationName, setOtherComplicationName] = useState("");
    const [newPatient, setNewPatient] = useState(false);



    const value = {
        showLoginDialog, setShowLoginDialog,
        showConsentDialog, setShowConsentDialog,
        openNewCenter, setOpenNewCenter,
        openNewUser, setOpenNewUser,
        buttonLoginLogout, setButtonLoginLogout,
        username, setUsername,
        password, setPassword,
        oldPassword, setOldPassword,
        newPassword1, setNewPassword1,
        newPassword2, setNewPassword2,
        selectedPatientId, setSelectedPatientId,
        selectedPatientIdentification, setSelectedPatientIdentification,
        currentPatient, setCurrentPatient,
        axiosPatient, setAxiosPatient,
        currentCenterId, setCurrentCenterId,
        isAuthenticated, setIsAuthenticated,
        currentUser, setCurrentUser,
        isChecked, setIsChecked,
        selectedCenterIdAdmin, setSelectedCenterIdAdmin,
        selectedUserId, setSelectedUserId,
        activeAdminTab, setActiveAdminTab,
        otherComplicationName, setOtherComplicationName,
        newPatient, setNewPatient
    };

    return (
        <GlobalContext.Provider value={value}>
            <div className="App">
                <BrowserRouter>
                    <Header/>
                    <AllRoutes/>
                </BrowserRouter>
            </div>
        </GlobalContext.Provider>
    );
}

export default App;
